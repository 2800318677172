import axiosDBClient from 'axiosDBClient';

export const getPriorityAccounts = async (unixId, accountType) => {
  const response = await axiosDBClient.get('filter/corp-parent-priority', {
    params: { unixId, field: accountType },
  });

  if (response.error) {
    throw new Error('Could not fetch accounts data!');
  }

  return response.data.result;
};

export const getAccountsOrSites = async (unixId, field, roleId) => {
  const response = await axiosDBClient.get('filter/corp-parent-priority', {
    params: { field: field, unixId: unixId, roleId: roleId },
  });

  if (response.data.error) {
    throw new Error('Could not fetch focus accounts for user ' + unixId);
  }

  return response.data.result;
};

/**
 * Gets time period data and formats it so
 * the Time Period dropdown can use it
 * also stores time period
 * store the kpi time period in the store
 * @param {*} kpiId the kpi id
 * @returns
 */
export const getTimePeriods = async (kpiId) => {
  const response = await axiosDBClient.get('filter/time-period', {
    params: { kpiId },
  });

  if (response.data.error) {
    throw new Error('Could not fetch time-periods!');
  }
  return response.data.result;
};

export const getTimePeriodOptions = async (timePeriods) => {
  const timePeriodOptions = timePeriods.map((timePeriod) => {
    return {
      label: timePeriod.timePeriodName,
      value: timePeriod.timePeriodId,
      timeSpan: timePeriod.timeSpan,
    };
  });

  return timePeriodOptions;
};

export const getDimensionsTimePeriodOptions = async (
  timePeriods,
  dimentionId
) => {
  const dimentinosTimePeriodOptions = timePeriods.filter(
    (timePeriod) => dimentionId === timePeriod?.dimensionId
  );
  const timePeriodOptions = dimentinosTimePeriodOptions.map((timePeriod) => {
    return {
      label: timePeriod.timePeriodName,
      value: timePeriod.timePeriodId,
      timeSpan: timePeriod.timeSpan,
    };
  });
  return timePeriodOptions;
};

export const getAllKpiAndDimension = async (roleId, view) => {
  const response = await axiosDBClient.get(
    `kpi/kpi-dimension?roleId=${roleId}&field=${view}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.error) {
    throw new Error('Could not kpi and dimensions data!');
  }
  return response.data;
};

export const getMultiDimensionChartData = async (
  measureObj,
  dimensionObj,
  appId,
  chartType,
  limit
) => {
  const payload = {
    appId: appId,
    limit: limit,
    measure: measureObj,

    dimension: dimensionObj,
    dataType: chartType,
  };
  const headers = {
    qlikCookie: localStorage.getItem('qlikCookie'),
    Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
  };
  let response = await axiosDBClient.post(
    '/analytics',
    JSON.stringify(payload),
    {
      headers: headers,
    }
  );
  if (response.data.error) {
    throw new Error('Could not fetch focus ');
  }

  return response.data;
};
