import axiosDBClient from 'axiosDBClient';
import { getArrayLabelValuesWithoutSpace, getFieldName } from 'utils/utils';
import {
  setEcosystemOptions,
  setProductOptions,
  setSelectedProduct,
  setSelectedSquad,
  setSquadOptions,
  setTimespan,
} from './trendsSlice';
import { isArray } from 'lodash';
import { trackPromise } from 'react-promise-tracker';
import { getQlikSenseData, setQlikAuth } from 'services/qlik.service';
import { setQlikToken } from 'store/auth/Authslice';
import axiosQlikClient from 'api';

export const GetEcosystemAPI = async (unixId, roleId) => {
  try {
    const params = { unixId, roleId };
    const response = await axiosDBClient.get('filter/ecosystem', { params });
    const ecosystems =
      response.data.result?.length > 0 ? response.data.result : [];
    return {
      options: ecosystems.map((item) => {
        return {
          ...item,
          label: item.ecosystemName,
          value: item.ecosystemId,
        };
      }),
      hasMore: false,
    };
  } catch (error) {
    console.log(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const GetSquatAPI = async (
  searchQuery,
  selectedProduct,
  userRestrictedRole,
  unixId,
  roleId
) => {
  try {
    const params = {
      products:
        selectedProduct?.length > 0
          ? JSON.stringify(
              getArrayLabelValuesWithoutSpace(selectedProduct).split(',')
            )
          : [],
      squadRestriction: userRestrictedRole?.squadRestriction,
      unixId,
      roleId,
      searchToken: searchQuery,
    };

    const response = await axiosDBClient.get('filter/squads', { params });

    return {
      options: response.data.result,
      hasMore: false,
    };
  } catch (error) {
    console.log(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const GetProductAPI = async (
  searchQuery,
  selectedSquad,
  userRestrictedRole,
  unixId,
  roleId
) => {
  try {
    const params = {
      squads:
        selectedSquad?.length > 0
          ? JSON.stringify(
              getArrayLabelValuesWithoutSpace(selectedSquad).split(',')
            )
          : [],
      productRestriction: userRestrictedRole?.productRestrictions,
      unixId,
      roleId,
      searchToken: searchQuery,
    };

    const response = await axiosDBClient.get('filter/products', { params });

    return {
      options: response.data.result,
      hasMore: false,
    };
  } catch (error) {
    console.log(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const GetTimePeriodAPI = async (kpiId) => {
  try {
    const params = { kpiId };
    const response = await axiosDBClient.get('filter/time-period', { params });
    const timePeriods =
      response.data.result?.length > 0 ? response.data.result : [];
    return {
      options: timePeriods.map((timePeriod) => {
        return {
          ...timePeriod,
          label: timePeriod.timePeriodName,
          value: timePeriod.timePeriodId,
        };
      }),
      hasMore: false,
    };
  } catch (error) {
    console.log(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const getEcosystems = async (unixId, roleId, customerId) => {
  const response = await axiosDBClient.get('filter/ecosystem', {
    params: { unixId, roleId, customerId },
  });

  const ecosystems = response.data.result;
  let ecosystemOptions = [];

  if (ecosystems?.length > 0) {
    ecosystemOptions = ecosystems.map((ecosystem) => {
      return {
        label: ecosystem.ecosystemName,
        value: ecosystem.ecosystemId,
      };
    });
  }
  return ecosystemOptions;
};

export const getEcosystemData = (unixId, roleId, customerId) => {
  return async (dispatch) => {
    try {
      const ecoSystem = await getEcosystems(unixId, roleId, customerId);
      if (ecoSystem?.length > 0) {
        dispatch(setEcosystemOptions(ecoSystem));
      }
    } catch (error) {}
  };
};

export const getSquadData = (selectedSquad) => {
  return async (dispatch) => {
    const getSquads = async () => {
      const url = `filter/squads`;
      const response = await axiosDBClient.get(url);
      if (response.data.error) {
        throw new Error('Could not fetch squads!');
      }
      return response.data.result;
    };

    try {
      const squads = await getSquads();
      dispatch(setSquadOptions(squads));
      if (isArray(selectedSquad)) {
        dispatch(setSelectedSquad(selectedSquad));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getProductData = (selectedProduct) => {
  return async (dispatch) => {
    const getProducts = async () => {
      const response = await axiosDBClient.get('filter/products');

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }

      return response.data.result;
    };

    try {
      const products = await getProducts();

      dispatch(setProductOptions(products));
      if (isArray(selectedProduct)) {
        dispatch(setSelectedProduct(selectedProduct));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllKpiList = async (roleId, flag) => {
  try {
    const response = await axiosDBClient.get(
      'v2/filter/kpi-list',
      { params: { roleId, flag } },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.error) {
      throw new Error('Could not kpi and dimensions data!');
    }
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const getKPIProps = async (kpiId) => {
  try {
    const response = await axiosDBClient.get(
      'v2/filter/kpi-props',
      { params: { kpiId } },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.error) {
      throw new Error('Could not kpi and dimensions data!');
    }
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const getTimespan = (expression, appId) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseData(expression, null, appId, null).then((data) => {
          dispatch(
            setTimespan(
              data.data.data.category[0].replace(new RegExp("'", 'g'), ' ')
            )
          );

          setQlikAuth(
            data?.headers['authorization']?.split(' ')[1],
            data?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: data?.headers['authorization']?.split(' ')[1],
              qlikCookie: data?.headers['qlikcookie'],
            })
          );
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const loadDropdownOption = async (params) => {
  try {
    const payload = { ...params, fieldName: getFieldName(params.fieldName) };
    const { values, currentListSize, hasMoreElement } =
      (await getDataforDropdown(payload)) || {};
    const dataOptions = values?.map((item) => ({
      label: item,
      value: item,
    }));
    const options = [...dataOptions];
    return {
      values: options,
      currentListSize,
      hasMoreElement,
    };
  } catch (err) {
    console.log(err); // eslint-disable
  }
};

export const getDataforDropdown = async ({
  appId,
  fieldName,
  offset,
  searchText,
  searchOffset,
  expression,
}) => {
  try {
    const url = 'v2/filter/dropdown';
    const response = await axiosQlikClient.get(url, {
      params: {
        appId,
        fieldName,
        offset: searchText?.length >= 3 ? searchOffset : offset,
        searchText,
        expression,
      },
      headers: {
        qlikCookie: localStorage.getItem('qlikCookie'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.error) {
      console.log(response.message);
      throw new Error('Could not fetch qlik data!');
    }

    return response?.data?.result;
  } catch (error) {
    console.log(error.message);
  }
};