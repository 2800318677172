export const trendsInitaiState = {
  localFiltersData: {},
  localFiltersDependencyData: {},
  KPIFilter: {
    Ecosystem: {
      value: [],
      options: [],
      dependencies: [],
      isDisabled: false,
      isMulti: true,
    },
    Squad: {
      value: [],
      options: [],
      dependencies: [
        'accountableProductsChecked',
        'Product.value',
        'firstLoad',
      ],
      isDisabled: false,
      isMulti: true,
    },
    Product: {
      value: [],
      options: [],
      dependencies: ['accountableProductsChecked', 'Squad.value', 'firstLoad'],
      isDisabled: false,
      isMulti: true,
    },
    TimePeriod: {
      value: [],
      options: [],
      dependencies: [],
      isDisabled: false,
      isMulti: false,
    },
  },
  selectedKPI: null,
  localFilters: [],
  customerTypes: [],
  userFocusAccounts: [],
  allAccountOptions: [],
  ecosystemOptions: [],
  productOptions: [],
  squadOptions: [],
  kpiOptions: [],
  dimensionOptions: [],
  allProductOptions: [],
  timePeriodOptions: [],
  timePeriods: [],
  defaultTimePeriod: {},
  selectedEcosystem: [],
  selectedProduct: [],
  selectedSquad: [],
  timePeriod: null,
  marketingFilters: {
    Channel: {
      options: [],
      selectedValue: [],
    },
    DiscussionTopic: {
      options: [],
      selectedValue: [],
    },
    PayerStakeholderType: {
      options: [],
      selectedValue: [],
    },

    TemplateName: {
      options: [],
      selectedValue: [],
    },
    SurveyType: {
      options: [],
      selectedValue: [],
    },
    PresentationName: {
      options: [],
      selectedValue: [],
    },
    KeyMessage: {
      options: [],
      selectedValue: [],
    },
  },

  timePeriodName: 'Oct 21-Sep 22',
  isAccountAvailable: true,
  viewName: 'Gross Sales by Account',
  noDimensionValue: '',
  benchmarkNoDimensionValue: '',
  chartType: 'KPI',
  oldChartType: '',
  chartData: [],
  tableData: [],
  cards: [],
  defaultCards: [],
  selectedKpi: {},
  expression: '',
  showSuccess: false,
  selectedAppId: 1,
  timespan: '',
  selectedAppLink: '',
  advanceButtonValue: false,
  KPIselectedStatus: false,
  advanceOptions: '',
  advanceDropdownOptions: [],
  systemAlertsData: [],
  notificationsData: [],
  whatsNewData: {},
  applyBtnStatusForProdAndSquad: false,
};

// [{id: 0, filed:"Ecosystem", dependencies: [],
//   isDisabled: false,
//   isMulti: true,}]
