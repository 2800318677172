const { useState, useEffect } = require('react');

export const useWindowDimension = () => {
  const initialWindowDimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  };
  const [windowDimension, setWindowDimension] = useState(
    initialWindowDimensions
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension(initialWindowDimensions);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimension;
};
