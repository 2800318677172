import {
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap';
import classes from '../home/configure-my-view/ConfigureMyViewLayout.module.scss';
import { useEffect, useState } from 'react';
import LinkButton from 'components/shared/button/LinkButton';
import {
  kpiTitleLimitExceedMessage,
  updatedKpiNameLength,
} from 'constants/constants';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const TileName = ({ data, defaultKPIName, handleTileChange }) => {
  const { selectedKPI } = useSelector((state) => state?.trends);

  const [isEditing, setIsEditing] = useState(false);
  const [updatedKpiName, setUpdatedKpiName] = useState(
    data?.title ? data?.title : defaultKPIName
  );
  const [kpiValue, setKpiValue] = useState(
    data?.title ? data?.title : defaultKPIName
  );

  const handleKPINameChange = (val) => {
    setKpiValue(val);
  };

  useEffect(() => {
    setUpdatedKpiName(defaultKPIName);
    setKpiValue(defaultKPIName);
  }, [defaultKPIName]);

  const updateKpiName = () => {
    setUpdatedKpiName(kpiValue);
    handleTileChange(kpiValue);
    if (kpiValue.length > updatedKpiNameLength) {
      toast.warning(kpiTitleLimitExceedMessage);
      setKpiValue(updatedKpiName);
    } else if (kpiValue !== updatedKpiName && kpiValue.length > 0) {
      setUpdatedKpiName(kpiValue);
    } else {
      setKpiValue(updatedKpiName);
    }
    setIsEditing(!isEditing);
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const tooltip = () => {
    return (
      <Tooltip
        placement='left'
        bsclass='gne-tooltip tooltip--left'>
        <p className='text text__x-small mb-1'>
          <strong>{selectedKPI?.Description + '.'}</strong>
        </p>
      </Tooltip>
    );
  };
  return (
    <Row className='d-flex align-items-center text-left ms-1'>
      <Col className='ps-0'>
        {isEditing ? (
          <div className='d-flex'>
            <label className='text__small d-flex justify-content-center w-100'>
              <Form.Control
                type='text'
                value={kpiValue}
                placeholder='KPI Card Title'
                className={`mt-1 text__small ${classes['form-control']}`}
                onChange={(e) => handleKPINameChange(e.target.value)}
              />
              <LinkButton
                onClick={updateKpiName}
                className='d-inline-block p-0'>
                <i className='icon icon__configure-checkbackground'></i>
              </LinkButton>
            </label>
          </div>
        ) : (
          <span className='title__bold d-flex justify-content-end'>
            <OverlayTrigger
              overlay={<Popover className='p-2'>{updatedKpiName}</Popover>}
              placement='right'>
              <span className={`${classes['title-name']}`}>
                {updatedKpiName}
              </span>
            </OverlayTrigger>

            <Col className='d-flex justify-content-start p-0 ms-1'>
              <LinkButton
                className='LinkButton_link-button'
                onClick={handleEdit}>
                <i className='icon icon__configureEdit '></i>
              </LinkButton>
              {selectedKPI?.Description && (
                <OverlayTrigger
                  overlay={tooltip()}
                  placement='bottom'>
                  <span
                    className={`d-inline-block justify-content-center  ${classes['info-icon']}`}>
                    <i className='icon icon__info__gray'></i>
                  </span>
                </OverlayTrigger>
              )}
            </Col>
          </span>
        )}
      </Col>
    </Row>
  );
};
export default TileName;
