import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { getUserLogs, getUserLogsExport } from 'services/org/org.service';
import {
  VIEWLOGTYPEOPTIONS,
  USERLOGACTIONOPTIONS,
  HCOSLOGACTIONOPTIONS,
  USERLOG,
} from 'constants/constants';
import classes from './ViewLogs.module.scss';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const ViewLogs = ({ logs, id, logsGridSize }) => {
  const [selectedLogType, setSelectedLogType] = useState(VIEWLOGTYPEOPTIONS[0]);
  const [selectedLogAction, setSelectedLogAction] = useState(
    USERLOGACTIONOPTIONS[0]
  );
  const [logType, setLogType] = useState(USERLOG);
  const [actionType, setActionType] = useState('all');
  const [userLogs, setUserLogs] = useState(logs);
  const [sortOrder, setSortOrder] = useState('desc');
  const [totalGridSize, setTotalGridSize] = useState(logsGridSize);
  const [dropdownOptions, setDropdownOptions] = useState(USERLOGACTIONOPTIONS);

  const columns = [
    {
      title: (
        <div className={classes['table-title']}>
          Date/Time
          <i className={`icon icon__sortDesc `}></i>
        </div>
      ),
      dataIndex: 'logDate',
      key: 'logDate',
      width: 300,
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
    },
    {
      title: 'Action',
      dataIndex: 'actionDetails',
      key: 'actionDetails',
      ellipsis: true,
    },
    {
      title: () => (
        <span
          className={classes['export-icon']}
          onClick={() => onExportlogs(logType, actionType, sortOrder, 0)}>
          <i className={`icon icon__excel`} />{' '}
        </span>
      ),
      dataIndex: 'columnSelection',
      key: 'columnSelection',
      width: 50,
      render: () => null,
    },
  ];

  const getUserLog = async (logType, action, order, offset) => {
    const data = await getUserLogs(logType, action, order, offset, id);
    setUserLogs(data?.values);
    setTotalGridSize(data?.currentListSize);
  };

  const onExportlogs = async (logType, actionType, sortOrder, offset) => {
    await getUserLogsExport(logType, actionType, sortOrder, 0, id);
  };

  useEffect(() => {
    getUserLog(logType, actionType, sortOrder, 0, id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedLogType(VIEWLOGTYPEOPTIONS[0]);
    setSelectedLogAction(USERLOGACTIONOPTIONS[0]);
    setLogType(USERLOG);
    setUserLogs(logs);
    setTotalGridSize(logsGridSize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDropdownOptions(
      logType === USERLOG ? USERLOGACTIONOPTIONS : HCOSLOGACTIONOPTIONS
    );
    setSelectedLogAction(
      logType === USERLOG ? USERLOGACTIONOPTIONS[0] : HCOSLOGACTIONOPTIONS[0]
    );
  }, [selectedLogType]);

  const onSelectLogType = (event) => {
    const action =
      event?.label === USERLOG
        ? USERLOGACTIONOPTIONS[0]?.value
        : HCOSLOGACTIONOPTIONS[0]?.value;
    setSelectedLogType(event);
    setLogType(event?.label);
    setActionType(action);
    getUserLog(event?.label, action, sortOrder, 0);
  };

  const onSelectLogsAction = (event) => {
    getUserLog(logType, event?.value, sortOrder, 0);
    setSelectedLogAction(event);
    setActionType(event?.value);
  };

  const handelTableChage = (pagination, _, { order }) => {
    const sortingOrder = order === 'ascend' ? 'asc' : 'desc';
    getUserLog(logType, actionType, sortingOrder, 0);
    setSortOrder(sortingOrder);
  };

  const pagination = {
    total: totalGridSize,
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  const paginationConfigs = pagination
    ? {
        ...pagination,
        itemRender: (page, type, originalElement) => {
          if (type === 'next') {
            return (
              <button
                className={classes.nextButton}
                disabled={false}>
                Next
              </button>
            );
          } else if (type === 'prev') {
            return (
              <button
                className={classes.nextButton}
                disabled={false}>
                Previous
              </button>
            );
          }
          return originalElement;
        },
      }
    : pagination;

  return (
    <div>
      <div className='d-flex'>
        <div className={classes['dropdown-wrap']}>
          <div className={classes['dropdown-label']}>Log</div>
          <GNESingleSelect
            value={selectedLogType}
            onChange={onSelectLogType}
            options={VIEWLOGTYPEOPTIONS}
            className={`single-select  ${classes['logs-select']}`}
          />
        </div>
        <div className={classes['dropdown-wrap']}>
          <div className={classes['dropdown-label']}>View</div>
          <GNESingleSelect
            value={selectedLogAction}
            onChange={onSelectLogsAction}
            options={dropdownOptions}
            className={`single-select  ${classes['logs-select']}`}
          />
        </div>
      </div>
      <Table
        columns={
          logType === USERLOG
            ? columns
            : columns.filter((col) => col.dataIndex !== 'userName')
        }
        dataSource={userLogs}
        size='small'
        onChange={handelTableChage}
        pagination={paginationConfigs}
      />
    </div>
  );
};

export default ViewLogs;
