import LinkButton from 'components/shared/button/LinkButton';
import GNEModal from 'components/shared/modal/Modal';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { discardImpactedCardsData } from 'store/home/home-my-view-actions';
const WarningOnHomeMyView = ({
  showConfirmation,
  setShowConfirmation,
  setShowGlobalwarning,
}) => {
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);

  const dispatch = useDispatch();
  let impactedTileExist = true;
  const handleCloseModal = () => {
    setShowConfirmation(false);
  };
  const handleDiscard = () => {
    if (impactedTileExist) {
      //call API to change the all flags to impact 'N'
      dispatch(discardImpactedCardsData(unixId, roleId)).then((res) => {
        setShowGlobalwarning(false);
        toast.success(
          'Warning messages on all tiles have been dismissed permanently.'
        );
      });
    } else {
      toast.success('The message has been dismissed successfully.');
    }
    setShowConfirmation(false);
  };
  const content = (
    <>
      <div className='p-0'>
        <div className='d-flex align-items-center'>
          <i className={`icon icon__tile-impact-warning-confirmation `}></i>
          <span className={`text text__bold text____text-medium`}>
            Warning on Home My View
          </span>
        </div>
        <div className={`text text__small pt-3`}>
          This will dismiss the warnings from all tiles and won’t display them
          again. You can still learn more about these changes in C360 by
          navigating to Help and selecting How-to Videos.
          <br /> <br />
          Are you sure you want to continue?
        </div>
      </div>
      <hr style={{ color: '#dcdcdc', marginBottom: 0 }}></hr>
      <div className={`d-flex justify-content-end w-97 mt-4`}>
        <LinkButton
          className='text text__small'
          onClick={handleCloseModal}>
          Cancel
        </LinkButton>

        <Button
          variant='btn btn-gne btn-primary'
          onClick={handleDiscard}
          style={{ width: '77px' }}
          className={`text__small ms-3`}>
          Yes
        </Button>
      </div>
    </>
  );
  return (
    <GNEModal
      show={showConfirmation}
      onHide={handleCloseModal}
      content={content}
      css='size-medium-lg'
      backdrop='static'
    />
  );
};

export default WarningOnHomeMyView;
