import 'animate.css';
import { searchResult } from 'components/customer/view/utils';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import {
  ALL,
  CONFIGURE_VIEW,
  DIMENSION,
  DIMENSION_TOOLTIP_TEXT,
  KPI_TOOLTIP_TEXT,
} from 'constants/constants';
import { isEqual, isNull } from 'lodash';
import { useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { wrapMenuList } from 'react-select-async-paginate';
import {
  getDimensionsTimePeriodOptions,
  getTimePeriodOptions,
} from 'services/trends.service';
import { getCustomerData } from 'store/customer/customer-view-actions';
import {
  setAdvanceSearchClicked,
  setAdvanceSearchSelectedData,
  setAllCustomers,
  setFocusAccountChecked,
  setPriorityCustomers,
  setSelectedCustomer,
} from 'store/customer/customerViewSlice';
import { getKPIProps } from 'store/trends_v2/trendsAction';
import {
  setAdvanceOptions,
  setChartType,
  setCustomerTypes,
  setDefaultTimePeriod,
  setDimensionOptions,
  setLocalFilters,
  setLocalFiltersData,
  setOldChartType,
  setSelectedAppId,
  setSelectedAppLink,
  setSelectedDimension,
  setSelectedKPI,
  setSelectedTimePeriod,
  setTimePeriodOptions,
  setTimePeriods,
} from 'store/trends_v2/trendsSlice';
import {
  ConvertSingleMultiSelectValue,
  dropdownOptions,
  getArrayValues,
  ValueContainer,
} from 'utils/utils';
import AdvanceSearch from '../shared/customer/AdvanceSearch';
import CustomerDropdown from '../shared/customer/CustomerDropdown';
import './KpiMapping.scss';

function KpiMapping({ page }) {
  const dispatch = useDispatch();
  const ecosystem = useSelector((state) => state.trends.KPIFilter.Ecosystem);
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );
  const focusCheck = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );

  const prevFocusAccountChecked = useSelector(
    (state) => state?.CustomerView?.prevFocusAccountChecked
  );

  const storedCustomerTypes = useSelector(
    (state) => state.trends.customerTypes
  );
  const { selectedKPI, selectedDimension, localFiltersData } = useSelector(
    (state) => state?.trends
  );
  const { kpiOptions, dimensionOptions } = useSelector(
    (state) => state?.trends
  );
  const { value: selectedEcosystem } = useSelector(
    (state) => state.trends.KPIFilter.Ecosystem
  );
  const [customerDisable, setCustomerDisable] = useState(
    selectedDimension?.filterEnable?.accountEnable === 'N'
  );

  const onKpiChange = async (value) => {
    if (value?.KpiId !== selectedKPI?.KpiId) {
      try {
        dispatch(setSelectedKPI(value));
        dispatch(setLocalFilters([]));
        // Dimensions
        const kpiPropsResult = await getKPIProps(value?.KpiId);
        const { dimensions, customerType, localFilter, timePeriod } =
          kpiPropsResult;

        let defaultDimension = dimensions[0];

        let isDimensionLevelExpression =
          defaultDimension?.filterEnable?.EnableDimensionLevelExpression ===
          'Y';
        setCustomerDisable(
          defaultDimension?.filterEnable?.accountEnable === 'N'
        );

        let resetObj = {};
        for (let item of localFilter) {
          if (localFiltersData[item.qExprClm]) {
            resetObj[item.qExprClm] = {
              ...localFiltersData[item.qExprClm],
            };
          } else {
            resetObj[item.qExprClm] = {
              ...localFiltersData[item.qExprClm],
              value: [],
            };
          }
        }
        dispatch(setLocalFiltersData(resetObj));
        dispatch(setLocalFilters(localFilter));
        dispatch(setDimensionOptions(dimensions));
        dispatch(
          setAdvanceOptions({
            compareBy: defaultDimension?.advanceOptions[0] ?? DIMENSION,
            selectDropdownOption: null,
          })
        );

        dispatch(setSelectedAppId(value.AppId));
        dispatch(setSelectedAppLink(value.AppLink));
        // Time Period
        const timePeriodOptions = isDimensionLevelExpression
          ? await getDimensionsTimePeriodOptions(
              timePeriod,
              defaultDimension?.dimensionId
            )
          : await getTimePeriodOptions(timePeriod);

        let exp = timePeriod.filter((tp) => tp.uXDefaultValue === 'Y')[0];

        dispatch(setTimePeriods(timePeriod));
        dispatch(setTimePeriodOptions(timePeriodOptions));

        dispatch(
          setSelectedTimePeriod({
            label: exp.timePeriodName,
            value: exp.timePeriodId,
          })
        );
        dispatch(
          setDefaultTimePeriod({
            label: exp.timePeriodName,
            value: exp.timePeriodId,
          })
        );
        dispatch(
          setChartType(getChartTypeByDimension(defaultDimension?.chartType))
        );
        dispatch(
          setOldChartType(getChartTypeByDimension(defaultDimension?.chartType))
        );

        // Call Customer API if there is a change in customer type
        const ecosystems = getEcosystem();
        if (
          (page === CONFIGURE_VIEW.trends || page === CONFIGURE_VIEW.myView) &&
          storedCustomerTypes?.length !== 0 &&
          !isEqual(customerType, storedCustomerTypes)
        ) {
          // Reset Advance search and customer type if there is a change in customer type b/w kpi
          dispatch(setAdvanceSearchSelectedData({}));

          // Customer API if focusCheck or prevFocusAccountChecked is true
          dispatch(setSelectedCustomer([]));
          const focusCheckCriteria =
            (prevFocusAccountChecked && !focusCheck) || focusCheck;
          if (focusCheckCriteria && !isNull(customerType)) {
            const response = await getCustomerData(
              true,
              '',
              0,
              roleId,
              unixId,
              false,
              ecosystems,
              customerType,
              '',
              '',
              '',
              true
            );
            const { data } = response.result;
            if (data?.length > 0) {
              dispatch(setPriorityCustomers(data));
              dispatch(setFocusAccountChecked(true));
            }
            dispatch(setAllCustomers(data));
          }
        }

        dispatch(setCustomerTypes(customerType));
        dispatch(setSelectedDimension(defaultDimension));
      } catch (error) {
        console.error('Error ', error);
      }
    }
  };
  const getEcosystem = () => {
    if (
      (page === CONFIGURE_VIEW.trends &&
        ecosystem?.value?.length > 0 &&
        !focusCheck) ||
      selectedDimension?.filterEnable?.ecosystemEnable === 'N'
    ) {
      return getArrayValues(ecosystem?.value).split(',');
    }
    return [];
  };
  const onDimensionChange = (value) => {
    if (selectedDimension?.dimensionId !== value?.dimensionId) {
      dispatch(setSelectedDimension(value));

      dispatch(setChartType(getChartTypeByDimension(value?.chartType)));
      dispatch(setOldChartType(getChartTypeByDimension(value?.chartType)));
      // Reset Advance Options on every dimension change
      dispatch(
        setAdvanceOptions({
          compareBy: value?.advanceOptions[0] ?? DIMENSION,
        })
      );
    }
  };

  const getChartTypeByDimension = (chartType) => {
    const barChart =
      chartType === 'Bar Chart'
        ? 'bar'
        : chartType === 'Line'
        ? 'line'
        : chartType === 'KPI'
        ? 'KPI'
        : null;
    return barChart;
  };

  const loadKpiOptions = async (searchQuery) => {
    let options = kpiOptions;
    if (searchQuery) {
      options = searchResult(kpiOptions, searchQuery, 'KpiName');
    }
    return {
      options: options,
      hasMore: false,
    };
  };

  const loadDimensionOptions = async (searchQuery) => {
    let options = dimensionOptions;
    if (searchQuery) {
      options = searchResult(dimensionOptions, searchQuery, 'dimensionName');
    }
    return {
      options: options,
      hasMore: false,
    };
  };

  const onChangeHandler = (selected) => {
    selected = ConvertSingleMultiSelectValue(selected);
    dispatch(setSelectedCustomer(selected));
  };

  return (
    <div className='main-container'>
      <Row className='kpi-container'>
        <Col
          className='pe-0'
          md={3}>
          <span className='text text__bold badge-circle mb-2'>1</span>{' '}
          <label className='text text__bold text__blue mb-1 me-1'>KPI</label>
          <OverlayTrigger
            placement={'right'}
            overlay={
              <Tooltip bsclass='gne-tooltip tooltip--left'>
                {KPI_TOOLTIP_TEXT}
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5],
                  },
                },
              ],
            }}>
            <i className='icon icon__info__gray'></i>
          </OverlayTrigger>
          <GNEAsyncPagination
            value={selectedKPI}
            loadOptions={loadKpiOptions}
            onChange={onKpiChange}
            isMulti={false}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={true}
            getOptionId={(option) => option?.KpiId}
            getOptionValue={(option) => option?.KpiId}
            getOptionLabel={(option) => option?.KpiName}
            onMenuOpen={() =>
              dispatch(
                setAdvanceSearchClicked({
                  id: CONFIGURE_VIEW.trends,
                  value: false,
                })
              )
            }
          />
        </Col>
        <Col md={3}>
          <span className='text text__bold badge-circle mb-2'>2</span>{' '}
          <label className='text text__bold text__blue mb-1'>Dimension</label>
          <OverlayTrigger
            placement={'right'}
            className=''
            overlay={
              <Tooltip bsclass='gne-tooltip tooltip--left'>
                {DIMENSION_TOOLTIP_TEXT}
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5],
                  },
                },
              ],
            }}>
            <i className='icon icon__info__gray info-icon'></i>
          </OverlayTrigger>
          <GNEAsyncPagination
            value={selectedDimension}
            loadOptions={loadDimensionOptions}
            onChange={onDimensionChange}
            isMulti={false}
            components={{
              ValueContainer: ValueContainer,
              Option: wrapMenuList(dropdownOptions),
            }}
            placeholder={ALL}
            closeMenuOnSelect={true}
            cacheUniqs={[selectedKPI]}
            getOptionId={(option) => option?.dimensionId}
            getOptionValue={(option) => option?.dimensionId}
            getOptionLabel={(option) => option?.dimensionName}
            onMenuOpen={() =>
              dispatch(
                setAdvanceSearchClicked({
                  id: CONFIGURE_VIEW.trends,
                  value: false,
                })
              )
            }
          />
        </Col>
        <Col
          md={6}
          className='mb-2 ps-0'>
          <CustomerDropdown
            page={CONFIGURE_VIEW.trends}
            isMulti={true}
            isDisabled={
              customerDisable ||
              selectedDimension?.filterEnable?.accountEnable === 'N' ||
              page === CONFIGURE_VIEW.insights
            }
            onChangeHandler={onChangeHandler}
            clearDependencies={selectedEcosystem}
            focusDisabled={false}
            focusChecked={true}
          />
        </Col>
      </Row>
      {advanceSearchClicked[CONFIGURE_VIEW.trends] && (
        <div className='animate__animated animate__fadeIn search-container viewList'>
          <AdvanceSearch page={CONFIGURE_VIEW.trends} />
        </div>
      )}
    </div>
  );
}

export default KpiMapping;
