import React from 'react';
import { Col, Row } from 'react-bootstrap';
import classes from './favorites/Favorites.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { discardImpactedCardsData } from 'store/home/home-my-view-actions';
import { toast } from 'react-toastify';
import { IMPACTED_GD_VIDEO_URL } from 'constants/constants';

const TileImpactWarning = ({ setShowConfirmation, setShowGlobalwarning }) => {
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);
  const impactedCards = useSelector(
    (state) => state?.HomeMyView?.impactedCards
  );
  const dispatch = useDispatch();
  const flag = impactedCards?.some((card) => card.isActive === 'Y' && card.type === 'GLOBAL');
  const handleCross = () => {
    if (!flag) {
      setShowConfirmation(true);
    } else {
      dispatch(discardImpactedCardsData(unixId, roleId)).then((res) => {
        setShowGlobalwarning(false);
        toast.success('The message has been dismissed successfully.');
      });
    }
  };
  return (
    <Row className={`${classes['global-warning']} p-2 ms-3 mb-1 me-2`}>
      <Col className='d-flex align-items-center justify-content-center ms-1 me-1 p-0 g-0'>
        <i className='icon icon__tile-impact-warning icon__tile-impact-warning__global'></i>
      </Col>
      <Col
        md={11}
        className='text text__x-small me-0 p-0 g-0 text_underline'>
        A recent change was introduced in C360 that may impact how you create
        tiles in the future. Please learn more about the changes{' '}
        <a
          href={IMPACTED_GD_VIDEO_URL}
          target='_blank'
          className='text text__bold'
          rel='noreferrer'>
          here
        </a>{' '}
        or click ‘X’ to dismiss permanently.
      </Col>
      <Col className='d-flex align-items-center justify-content-end m-0 p-0 g-0'>
        {' '}
        <i
          className='icon icon__close-warning icon__close-warning__global'
          onClick={handleCross}></i>
      </Col>
    </Row>
  );
};

export default TileImpactWarning;
