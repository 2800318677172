import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
import SitePerformance from '../site-performance/SitePerformance';
import AccountPayerMix from '../payer-mix/AccountPayerMix';
import SiteUtilization from '../site-utilization/SiteUtilization';
import ProductMix from '../product-mix/ProductMix';
import PayerMix from '../payer-mix/PayerMix';
import Affiliations from '../affiliations/Affiliations';
import SiteAffiliations from '../affiliations/SiteAffiliation';
import CopayFRMView from '../copay-analytics/CopayFRMView';
import CopayHDView from '../copay-analytics/CopayHDView';
import Banner from '../banner/Banner';
import SectionMenu from '../section-menu/SectionMenu';
import { Col, Button } from 'react-bootstrap';
import Insights from '../insights/Insights';
import Engagements from '../engagements/Engagements';

import classes from '../view/View.module.scss';
import { toast } from 'react-toastify';
import { addToInsightsBoard } from 'store/customer/customer-view-actions';
import {
  setCards,
  setCustomerInsightBoardId,
  setCustomerViewButtonDisabled,
  setCustomerViewButtonText,
} from 'store/customer/customerViewSlice';
import { CUSTOMER_VIEW_UPDATE_TO_INSIGHTS } from 'constants/constants';

const HealthSystem = (props) => {
  const dispatch = useDispatch();
  const insightType = useSelector((state) => state?.CustomerView?.insightType);
  const isSampleInsight = insightType === 'sample' ? true : false;
  const buttonText = useSelector(
    (state) => state?.CustomerView?.customerViewButtonText
  );
  const isUpdate = buttonText.toLowerCase().indexOf('update') > -1;

  const cards = useSelector((state) => state?.CustomerView?.cards);
  const customerViewButtonDisabled = useSelector(
    (state) => state.CustomerView.customerViewButtonDisabled
  );
  const selectedAccount = useSelector(
    (state) => state?.CustomerView?.selectedAccount
  );
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );

  const focusAccountChecked = useSelector(
    (state) => state.CustomerView?.focusAccountChecked
  );

  const insightsBrdId = JSON.parse(
    JSON.stringify(
      useSelector((state) => state.CustomerView?.custInsightsBrdId)
    )
  );
  const notificationId = useRef(null);

  useEffect(() => {
    if (!customerViewButtonDisabled && selectedAccount) {
      toast.dismiss(notificationId.current);
      notificationId.current = toast.warning(
        `Your recent edits to the insights section in this customer view haven't been saved yet. To keep your changes,
      click the '${buttonText}’ button. If you don't save, your changes will be lost.`,
        {
          position: 'bottom-left',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
          className: 'warningMsgAlignment',
        }
      );
    } else {
      toast.dismiss(notificationId.current);
    }
    return () => {
      toast.dismiss(notificationId.current);
    };
    // eslint-disable-next-line
  }, [cards]);

  const addUpdateInsightsBoard = async (isUpdate) => {
    const cardsData = cards?.map((item) => {
      return {
        title: item.title,
        cardId: item.cardId,
        custInsightsBrdId: item.custInsightsBrdId,
        kpiId: item.kpiId,
        expression: item.expression,
        appId: item.appId,
        cardView: item.cardView,
        filter: item.filter,
        positionIndex: item.positionIndex,
        unixId,
        isDeleted: item?.isDeleted === true ? true : false,
        timespan: item.timespan,
        advanceEnabled: item.advanceEnabled,
        advanceType: item.advanceType,
        advanceId: item.advanceId,
        adavanceTimeSpanExpression: item.adavanceTimeSpanExpression,
        advanceFilterValue: item.advanceFilterValue ?? [],
        roleId: roleId,
        kpiFilters: item.kpiFilters,
        timeSpan: item.timeSpan,
      };
    });

    const payload = {
      name: `${selectedCustomer?.label}`,
      unixId,
      accountId: selectedCustomer?.value,
      custInsightsBrdId: isUpdate
        ? insightsBrdId
        : cards?.[0]?.custInsightsBrdId,
      isPriorityAccount: focusAccountChecked === true ? 'Y' : 'N',
      cardsData,
      boardType: selectedView,
      roleId,
    };

    const data = await addToInsightsBoard(payload, isUpdate);
    const { cardId: cardIdsArray, custInsightsBrdId, message } = data;

    const updatedCards = cards
      ?.filter((crd) => crd.isDeleted !== true)
      ?.map((item, index) => {
        return {
          ...item,
          cardId: cardIdsArray?.[index],
          custInsightsBrdId,
        };
      });
    dispatch(setCards(updatedCards));

    dispatch(setCustomerInsightBoardId(custInsightsBrdId));
    dispatch(setCustomerViewButtonText(CUSTOMER_VIEW_UPDATE_TO_INSIGHTS));
    dispatch(setCustomerViewButtonDisabled(true));
    toast.success(message);
  };

  const { loadComponents, selectedView, componentAccessList } = props;
  return (
    <>
      {loadComponents && (
        <>
          <Fade>
            <Banner data={selectedView} />
          </Fade>
          <SectionMenu
            data={selectedView}
            role={roleId}
          />
          <Col
            md={12}
            xs={12}
            className='d-flex justify-content-end align-items-right mt-3'>
            {!isSampleInsight && (
              <Button
                variant={` btn-gne ${
                  isUpdate
                    ? 'btn-primary'
                    : 'btn-outline-primary btn-gne__outline'
                }`}
                onClick={() => addUpdateInsightsBoard(isUpdate)}
                disabled={cards?.length === 0 || customerViewButtonDisabled}>
                <div className={classes['update-insight-btn-label']}>
                  {!isUpdate && (
                    <i
                      className='icon icon__add'
                      style={{ marginTop: 2 }}></i>
                  )}
                  {buttonText}
                </div>
              </Button>
            )}
          </Col>
          <Fade>
            <Insights />
          </Fade>
          <Fade>
            <Engagements />
          </Fade>
          {componentAccessList?.includes('Site-Utilization') && (
            <Fade>
              <SiteUtilization />
            </Fade>
          )}
          {selectedView === 'account' &&
            componentAccessList?.includes('Site-Performance') && (
              <Fade>
                <SitePerformance />
              </Fade>
            )}
          {selectedView === 'account' &&
            componentAccessList?.includes('Payer-Mix-Account') && (
              <Fade>
                <AccountPayerMix />
              </Fade>
            )}

          {selectedView === 'site' &&
            componentAccessList?.includes('Product-Mix') && (
              <Fade>
                <ProductMix />
              </Fade>
            )}
          {selectedView === 'site' &&
            componentAccessList?.includes('Payer-Mix') && (
              <Fade>
                <PayerMix />
              </Fade>
            )}
          {selectedView === 'account' && (
            <Fade>
              <Affiliations
                data={selectedView}
                role={roleId}
              />
            </Fade>
          )}
          {selectedView === 'site' && (
            <Fade>
              <SiteAffiliations
                data={selectedView}
                role={roleId}
                filterExp=''
                enableTitle={true}
                tableType={'siteView'}
              />
            </Fade>
          )}
          {componentAccessList?.includes('Copay-FRM') && (
            <Fade>
              <div
                className='full-width-in-container light-grey'
                id='copayFRMView'>
                <CopayFRMView />
              </div>
            </Fade>
          )}
          {componentAccessList?.includes('Copay-HD') && (
            <Fade>
              <div
                className='full-width-in-container light-grey'
                id='copayHDView'>
                <CopayHDView />
              </div>
            </Fade>
          )}
        </>
      )}
    </>
  );
};

export default HealthSystem;
