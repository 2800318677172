import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';

import classes from './AffiliationsFilter.module.scss';
import {
  setAffiliationsExpression,
  setAffiliationFilters,
} from 'store/customer/customerViewSlice';
import { customerViewInitialState } from 'store/customer/customerViewInitialState';
import { AFFILATION_FILTER_NAME } from 'constants/constants';
import { getCustomerExpr, isArray } from 'utils/utils';

const AffiliationsFilters = (props) => {
  const APP_ID = process.env.REACT_APP_AFFILIATION_APP_ID;
  const [fieldExpression, setFieldExpression] = useState('');
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const selectedView = useSelector(
    (state) => state?.CustomerView?.selectedView
  );
  const AffiliationFilter = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters
  );
  const selectedSiteName = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteName_Address
  );
  const selectedSiteState = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteState
  );
  const selectedSiteHIN = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteHIN
  );
  const selectedSiteAddress = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteAddress
  );
  const selectedHCPStakeholderType = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPStakeholderTypes
  );
  const selectedHCPName = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPNames
  );
  const selectedHCPAddress = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.HCPAddress
  );
  const selectedSiteSpeciality = useSelector(
    (state) => state?.CustomerView?.affiliationsFilters?.SiteSpeciality
  );
  const [menuIsOpen, setMenuIsOpen] = useState({
    SiteName_Address: false,
    SiteState: false,
    SiteHIN: false,
    SiteAddress: false,
    HCPStakeholderTypes: false,
    HCPNames: false,
    HCPAddress: false,
    SiteSpeciality: false,
  });
  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);

  useEffect(() => {
    dispatch(
      setAffiliationFilters(customerViewInitialState.affiliationsFilters)
    );
    onMenuOpen('');
    // eslint-disable-next-line
  }, [props?.site?.name]);

  useEffect(() => {
    const siteNameExpression =
      selectedSiteName?.selectedValue?.length > 0
        ? ',Site_ID={"' +
          selectedSiteName?.selectedValue?.map((a) => a?.value).join('","') +
          '"}'
        : '';
    const siteStateExpression =
      selectedSiteState?.selectedValue?.length > 0
        ? ',[Site State]={"' +
          selectedSiteState?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    const SiteHINExpression =
      selectedSiteHIN?.selectedValue?.length > 0
        ? ',[site_hin]={"' +
          selectedSiteHIN?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    const SiteAddressExpression =
      selectedSiteAddress?.selectedValue?.length > 0
        ? ',[Site Address]={"' +
          selectedSiteAddress?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    const HCPStakeholderTypeExpression =
      selectedHCPStakeholderType?.selectedValue?.length > 0
        ? ',[Stakeholder_Type]={"' +
          selectedHCPStakeholderType?.selectedValue
            ?.map((a) => a?.label)
            .join('","') +
          '"}'
        : '';
    const HCPNamExpression =
      selectedHCPName?.selectedValue?.length > 0
        ? ',[HCP Name]={"' +
          selectedHCPName?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    const HCPAddressExpression =
      selectedHCPAddress?.selectedValue?.length > 0
        ? ',[HCP Address]={"' +
          selectedHCPAddress?.selectedValue?.map((a) => a?.label).join('","') +
          '"}'
        : '';
    const SiteSpecialityExpression =
      selectedSiteSpeciality?.selectedValue?.length > 0
        ? ',[HCP Speciality]={"' +
          selectedSiteSpeciality?.selectedValue
            ?.map((a) => a?.label)
            .join('","') +
          '"}'
        : '';
    let filterExp;
    if (selectedView === 'account') {
      filterExp = `
      ${getCustomerExpr(
        selectedCustomer,
        isArray(selectedCustomer)
      )} ${siteNameExpression} ${siteStateExpression} ${SiteHINExpression} ${SiteAddressExpression} ${HCPStakeholderTypeExpression} ${HCPNamExpression}  ${HCPAddressExpression}
      `;
      props.applyFilter(filterExp);
      setFieldExpression(filterExp);
      dispatch(setAffiliationsExpression(filterExp));
    } else {
      filterExp = `
      {${props.site.id}}  ${HCPStakeholderTypeExpression} ${HCPNamExpression}  ${HCPAddressExpression} ${SiteSpecialityExpression}
    `;
      let exp = `
      ${getCustomerExpr(
        selectedCustomer,
        isArray(selectedCustomer)
      )}  ${HCPStakeholderTypeExpression} ${HCPNamExpression}  ${HCPAddressExpression} ${SiteSpecialityExpression}
  `;
      props.applyFilter(filterExp);
      setFieldExpression(exp);
      dispatch(setAffiliationsExpression(exp));
    }
  }, [
    selectedHCPName,
    selectedSiteState,
    selectedSiteHIN,
    selectedHCPStakeholderType,
    selectedSiteName,
    selectedHCPAddress,
    selectedSiteAddress,
    selectedSiteSpeciality,
    selectedCustomer,
  ]);

  return (
    <div
      className={`animate__animated animate__fadeIn w-100 ${classes['filter-container-actions']}  ${classes.info}`}>
      <div>
        <Row>
          {selectedView === 'account' ? (
            <>
              <Col
                md={3}
                className='mb-3'>
                <label className='title__x-small mb-1'>Site Name</label>
                <SelectWithPagination
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  filter={AffiliationFilter}
                  filterName={AFFILATION_FILTER_NAME}
                  fieldName='SiteName_Address'
                  isMulti={true}
                  appid={APP_ID}
                  isDefault={false}
                  expression={fieldExpression}
                  cacheUniqs={[
                    selectedSiteState,
                    selectedSiteHIN,
                    selectedSiteAddress,
                    selectedHCPName,
                    selectedHCPAddress,
                    selectedHCPStakeholderType,
                    selectedSiteSpeciality,
                    selectedCustomer,
                  ]}
                />
              </Col>
              <Col
                md={3}
                className='mb-3'>
                <label className='title__x-small mb-1'>State</label>
                <SelectWithPagination
                  filter={AffiliationFilter}
                  fieldName='SiteState'
                  filterName={AFFILATION_FILTER_NAME}
                  isMulti={true}
                  appid={APP_ID}
                  isDefault={false}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  expression={fieldExpression}
                  cacheUniqs={[
                    selectedSiteName,
                    selectedSiteHIN,
                    selectedSiteAddress,
                    selectedHCPName,
                    selectedHCPAddress,
                    selectedHCPStakeholderType,
                    selectedSiteSpeciality,
                    selectedCustomer,
                  ]}
                />
              </Col>
              <Col
                md={3}
                className='mb-3'>
                <label className='title__x-small mb-1'>Site HIN</label>
                <SelectWithPagination
                  filter={AffiliationFilter}
                  fieldName='SiteHIN'
                  filterName={AFFILATION_FILTER_NAME}
                  isMulti={true}
                  appid={APP_ID}
                  isDefault={false}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  expression={fieldExpression}
                  cacheUniqs={[
                    selectedSiteName,
                    selectedSiteState,
                    selectedSiteAddress,
                    selectedHCPName,
                    selectedHCPAddress,
                    selectedHCPStakeholderType,
                    selectedSiteSpeciality,
                    selectedCustomer,
                  ]}
                />
              </Col>
              <Col
                md={3}
                className='mb-3'>
                <label className='title__x-small mb-1'>Site Address</label>
                <SelectWithPagination
                  filter={AffiliationFilter}
                  fieldName='SiteAddress'
                  filterName={AFFILATION_FILTER_NAME}
                  isMulti={true}
                  appid={APP_ID}
                  isDefault={false}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={onMenuOpen}
                  expression={fieldExpression}
                  cacheUniqs={[
                    selectedSiteName,
                    selectedSiteState,
                    selectedSiteHIN,
                    selectedHCPName,
                    selectedHCPAddress,
                    selectedHCPStakeholderType,
                    selectedSiteSpeciality,
                    selectedCustomer,
                  ]}
                />
              </Col>
            </>
          ) : (
            ''
          )}
          <Col
            md={selectedView === 'account' ? 4 : 3}
            className='mb-3'>
            <label className='title__x-small mb-1'>Stakeholder Name</label>
            <SelectWithPagination
              filter={AffiliationFilter}
              fieldName='HCPNames'
              filterName={AFFILATION_FILTER_NAME}
              isMulti={true}
              appid={APP_ID}
              isDefault={false}
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              expression={fieldExpression}
              cacheUniqs={[
                selectedSiteName,
                selectedSiteState,
                selectedSiteHIN,
                selectedSiteAddress,
                selectedHCPAddress,
                selectedHCPStakeholderType,
                selectedSiteSpeciality,
                selectedCustomer,
              ]}
            />
          </Col>
          {selectedView === 'account' ? (
            ''
          ) : (
            <Col
              md={3}
              className='mb-3'>
              <label className='title__x-small mb-1'>Primary Speciality</label>
              <SelectWithPagination
                filter={AffiliationFilter}
                fieldName='SiteSpeciality'
                filterName={AFFILATION_FILTER_NAME}
                isMulti={true}
                appid={APP_ID}
                isDefault={false}
                menuIsOpen={menuIsOpen}
                onMenuOpen={onMenuOpen}
                expression={fieldExpression}
                cacheUniqs={[
                  selectedSiteName,
                  selectedSiteState,
                  selectedSiteHIN,
                  selectedSiteAddress,
                  selectedHCPName,
                  selectedHCPAddress,
                  selectedHCPStakeholderType,
                  selectedCustomer,
                ]}
              />
            </Col>
          )}
          <Col
            md={selectedView === 'account' ? 4 : 3}
            className='mb-3'>
            <label className='title__x-small mb-1'>Stakeholder Type </label>
            <SelectWithPagination
              filter={AffiliationFilter}
              fieldName='HCPStakeholderTypes'
              filterName={AFFILATION_FILTER_NAME}
              isMulti={true}
              appid={APP_ID}
              isDefault={false}
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              expression={fieldExpression}
              cacheUniqs={[
                selectedSiteName,
                selectedSiteState,
                selectedSiteHIN,
                selectedSiteAddress,
                selectedHCPName,
                selectedHCPAddress,
                selectedSiteSpeciality,
                selectedCustomer,
              ]}
            />
          </Col>

          <Col
            md={selectedView === 'account' ? 4 : 3}
            className='mb-3'>
            <label className='title__x-small mb-1'>Stakeholder Address</label>
            <SelectWithPagination
              filter={AffiliationFilter}
              filterName={AFFILATION_FILTER_NAME}
              fieldName='HCPAddress'
              isMulti={true}
              appid={APP_ID}
              isDefault={false}
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              expression={fieldExpression}
              cacheUniqs={[
                selectedSiteName,
                selectedSiteState,
                selectedSiteHIN,
                selectedSiteAddress,
                selectedHCPName,
                selectedHCPStakeholderType,
                selectedSiteSpeciality,
                selectedCustomer,
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AffiliationsFilters;
