import React, { useRef, useState, memo } from 'react';
import { Card, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import isEmpty from 'lodash/isEmpty';
import { usePromiseTracker } from 'react-promise-tracker';
import classes from './Tiles.module.scss';
import Chart from 'components/charts/Echart';
import TableView from 'components/charts/TableView';
import LinkButton from 'components/shared/button/LinkButton';
import TileActions from './TileActions';
import TileDetails from './TileDetails';
import { updateCardTitle } from 'store/home/home-my-view-actions';
import { updateInsightCardTitle } from 'store/customer/customer-view-actions';
import { setCards } from 'store/home/homeMyViewSlice';
import { setCards as setCustomerViewCard } from 'store/customer/customerViewSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatedKpiNameLength,
  kpiTitleMessage,
  kpiTitleLimitExceedMessage,
  CONFIGURE_VIEW,
  COMPARE_KPI,
} from 'constants/constants';
import { toast } from 'react-toastify';
import NoDimension from '../no-dimension/NoDimensions';
import EcosystemAlignmentChange from './EcosystemAlignmentChange';
import { RiseLoader } from 'react-spinners';

const Tile = memo(({ card, page, area }) => {
  const { promiseInProgress } = usePromiseTracker({ area: area });
  const [view, setView] = useState(card.cardView || 'chart');
  const ref = useRef(null);
  const dispatch = useDispatch();
  const customerViewCards = useSelector((state) => state?.CustomerView?.cards);
  const homeViewCards = useSelector((state) => state?.HomeMyView?.cards);
  const insightType = useSelector((state) => state?.CustomerView?.insightType);
  const isSampleInsight =
    insightType === 'sample' && page === CONFIGURE_VIEW.insights ? true : false;
  const buttonText = useSelector(
    (state) => state?.CustomerView?.customerViewButtonText
  );
  const customerViewButtonDisabled = useSelector(
    (state) => state.CustomerView.customerViewButtonDisabled
  );

  // eslint-disable-next-line no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    item: { id: card.cardId },
    type: 'CARD',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    // Accept will make sure only these element
    // type can be droppable on this element
    accept: 'CARD',
    hover(item) {
      return item;
    },
  });

  drag(drop(ref));
  const { filter: filterData, isAlignmentChange } = card;
  const { kpiName = '', selectedKPI = {}, chartType = 'bar' } = filterData;
  const noDimensionValue =
    card?.chartData?.values?.length > 0 && !card?.chartData?.category[0]
      ? card?.chartData?.values[0]?.data?.[0]
      : card?.chartData?.category?.[0];
  const benchmarkNoDimensionValue =
    card?.chartData?.values?.length > 0 &&
    card?.chartData?.values[1]?.data?.[0] !== undefined
      ? card?.chartData?.values[1]?.data?.[0]
      : '';

  const selectedDimension = selectedKPI?.dimensions[0];
  const viewName =
    selectedDimension?.dimensionName === 'No Dimension'
      ? kpiName
      : `${kpiName} by ${selectedDimension?.dimensionName}`;
  const isNodimension = selectedDimension?.dimensionName === 'No Dimension';
  const isValue = noDimensionValue > 0;
  const isColor =
    kpiName?.slice(-3) === '(%)' || kpiName?.toLowerCase().includes('change');

  const noData =
    (card?.chartData?.values?.length === 0 ||
      card?.chartData?.category[0] === null) &&
    !noDimensionValue;

  const { title } = card;

  const [updatedKpiName, setUpdatedKpiName] = useState(title);
  const [inputValueHomeTile, setInputValueHomeTile] = useState(title);

  const [showKPIName, setShowKPIName] = useState(false);

  const [updatedInsightKpiName, setInsightUpdatedKpiName] = useState(title);
  const [inputValueInsightTile, setInputValueInsightTile] = useState(title);

  const [showInsightKPIName, setShowInsightKPIName] = useState(false);

  const handleEdit = () => {
    setShowKPIName(true);
  };

  const handleInsightEdit = () => {
    setShowInsightKPIName(true);
  };

  const handleKPINameChange = (val) => {
    if (val.length < updatedKpiNameLength) {
      setUpdatedKpiName(val);
      setInputValueHomeTile(val);
    } else if (val.length > updatedKpiNameLength) {
      setInputValueHomeTile(val);
    }
  };

  const handleInsightKPINameChange = (val) => {
    if (val.length < updatedKpiNameLength) {
      setInsightUpdatedKpiName(val);
      setInputValueInsightTile(val);
    } else if (val.length > updatedKpiNameLength) {
      setInputValueInsightTile(val);
    }
  };

  const updateTitleState = (cardId) => {
    const updatedCards = homeViewCards.map((crd) => {
      if (crd.cardId === cardId) {
        return { ...crd, title: updatedKpiName };
      }
      return crd;
    });

    dispatch(setCards(updatedCards));
  };

  const updateInsightTitleState = (positionIndex) => {
    const updatedCards = customerViewCards.map((crd) => {
      if (crd.positionIndex === positionIndex) {
        return { ...crd, title: updatedInsightKpiName };
      }
      return crd;
    });

    dispatch(setCustomerViewCard(updatedCards));
  };

  const updateKPIName = async () => {
    let { cardId, unixId } = card;
    let tileName = !updatedKpiName ? card.title : updatedKpiName;
    setUpdatedKpiName(tileName);

    if (page === 'my view') {
      if (inputValueHomeTile.length > updatedKpiNameLength) {
        toast.warning(kpiTitleLimitExceedMessage);
      } else if (updatedKpiName !== card.title && updatedKpiName.length > 0) {
        await updateCardTitle(cardId, updatedKpiName, unixId);
        updateTitleState(cardId);
        toast.success(kpiTitleMessage);
      }
      setInputValueHomeTile(card.title);
      setShowKPIName(false);
    }
  };
  const updateInsightKPIName = async () => {
    let { cardId, unixId, positionIndex } = card;
    let tileName = !updatedInsightKpiName ? card.title : updatedInsightKpiName;
    setInsightUpdatedKpiName(tileName);
    if (page === 'Insights') {
      if (inputValueInsightTile.length > updatedKpiNameLength) {
        if (
          buttonText === 'Update Insight Board' &&
          customerViewButtonDisabled === true
        ) {
          toast.warning(kpiTitleLimitExceedMessage);
        }
      } else if (
        updatedInsightKpiName !== card.title &&
        updatedInsightKpiName.length > 0
      ) {
        if (cardId !== null) {
          await updateInsightCardTitle(cardId, updatedInsightKpiName, unixId);

          if (buttonText === 'Update Insight Board') {
            toast.success(kpiTitleMessage);
          }
        }
        updateInsightTitleState(positionIndex);
      }
      setInputValueInsightTile(card.title);
      setShowInsightKPIName(false);
    }
  };
  const CardTitleDisplay = ({
    updatedKpiName,
    viewName,
    onEdit,
    isSampleInsight,
  }) => (
    <>
      <span className={classes['card-title-span']}>
        {updatedKpiName ? (
          <OverlayTrigger
            overlay={<Popover className='p-2'>{updatedKpiName}</Popover>}
            placement='right'>
            <div className={classes['card-title-div']}>{updatedKpiName}</div>
          </OverlayTrigger>
        ) : (
          viewName
        )}
      </span>
      {!isSampleInsight && page !== CONFIGURE_VIEW.myDefault && (
        <LinkButton onClick={onEdit}>
          <i className='icon icon__configureEditTile d-flex justify-content-end p-0'></i>
        </LinkButton>
      )}
    </>
  );
  const isAdvanceEnabled = card.advanceEnabled === 'Y';
  const isCompareKpi = card?.advanceType === COMPARE_KPI;
  const renderLinkButton = (viewType, iconClass, isDisabled) => (
    <LinkButton
      onClick={() => setView(viewType)}
      className='d-inline-block p-0'
      disabled={isDisabled}>
      <i
        className={`icon ${iconClass}${
          view === viewType ? '--active' : ''
        } border-0`}></i>
    </LinkButton>
  );
  return (
    <>
      <Card
        ref={drag}
        className='gne-tile'>
        <Card.Body className='board-card'>
          <Card.Title
            className={`${classes['tile-title-wrap']} d-flex align-items-center`}>
            <div className={`d-flex  ${classes['card-title-tile']}`}>
              {page === 'my view' ? (
                <>
                  {showKPIName ? (
                    <>
                      <label className='text__x-small d-flex justify-content-center w-75'>
                        <Form.Control
                          type='text'
                          value={inputValueHomeTile}
                          placeholder='KPI Card Title'
                          className={`mt-1 text__small ${classes['text-filed-right-border']}`}
                          onChange={(e) => handleKPINameChange(e.target.value)}
                        />
                      </label>
                      <LinkButton
                        onClick={updateKPIName}
                        className='d-inline-block p-0'>
                        <i className='icon icon__configure-checkbackground'></i>
                      </LinkButton>
                    </>
                  ) : (
                    <CardTitleDisplay
                      updatedKpiName={updatedKpiName}
                      viewName={viewName}
                      onEdit={handleEdit}
                    />
                  )}
                </>
              ) : showInsightKPIName ? (
                <>
                  <label className='text__x-small d-flex justify-content-center w-75'>
                    <Form.Control
                      type='text'
                      value={inputValueInsightTile}
                      placeholder='KPI Card Title'
                      className={`mt-1 text__small ${classes['text-filed-right-border']}`}
                      onChange={(e) =>
                        handleInsightKPINameChange(e.target.value)
                      }
                    />
                  </label>
                  <LinkButton
                    onClick={updateInsightKPIName}
                    className='d-inline-block p-0'>
                    <i className='icon icon__configure-checkbackground'></i>
                  </LinkButton>
                </>
              ) : (
                <CardTitleDisplay
                  updatedKpiName={updatedInsightKpiName}
                  viewName={viewName}
                  onEdit={handleInsightEdit}
                  isSampleInsight={isSampleInsight}
                />
              )}
            </div>

            <div
              className={`justify-content-end ${classes['cta-wrapper']}`}
              // Preventing prapogation is needed beacuse Dnd is blocking mouseDown and other event
              onMouseDown={(e) => e.stopPropagation()}>
              <TileActions
                card={card}
                page={page}
              />
            </div>
          </Card.Title>

          <Card.Subtitle>
            <div className='d-flex align-items-center mb-3'>
              <p className='sub-title sub-title__x-small text text__grey mb-0 me-2'>
                {card.timeSpan ? card.timeSpan : '-'}
              </p>
              {!isNodimension ? (
                <>
                  {renderLinkButton(
                    'chart',
                    'icon__chartView',
                    isAdvanceEnabled && isCompareKpi
                  )}
                  {renderLinkButton('table', 'icon__tableView', false)}
                  {renderLinkButton(
                    'hash',
                    'icon__hashtag',
                    isAdvanceEnabled || isCompareKpi
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          </Card.Subtitle>

          <div className='d-flex flex-grow-1 justify-content-center'>
            {isAlignmentChange === 'Y' ? (
              <EcosystemAlignmentChange />
            ) : promiseInProgress ? (
              <RiseLoader
                type='ThreeDots'
                color='#004677'
                size={4}
              />
            ) : isNodimension && !noData ? (
              <div className={classes['no-dimension-tile']}>
                <NoDimension
                  isColor={isColor}
                  noDimensionValue={noDimensionValue}
                  benchmarkNoDimensionValue={benchmarkNoDimensionValue}
                  kpiName={kpiName}
                  isValue={isValue}
                />
              </div>
            ) : card && card.chartData && view !== 'table' ? (
              <>
                {!isEmpty(card?.chartData) && !noData ? (
                  <Chart
                    component='tile'
                    chartData={card?.chartData}
                    kpiName={kpiName}
                    chartType={chartType}
                    isMultiChart={true}
                    hashView={view === 'hash'}
                    style={{ height: '100%', width: '97%' }}
                    numberOfBar={4}
                    isDefaultScroll={false}
                  />
                ) : null}
              </>
            ) : card && card.chartData && view === 'table' ? (
              <>
                {!isEmpty(card?.chartData) && !noData && (
                  <div className={classes['scrollable-table-div']}>
                    <TableView
                      data={card?.chartData}
                      kpiName={kpiName}
                      dimensionName={selectedDimension?.dimensionName}
                      page={'tile'}
                    />
                  </div>
                )}
              </>
            ) : null}

            {noData && !promiseInProgress && isAlignmentChange !== 'Y' && (
              <i className='icon icon__no-data center-position'></i>
            )}
          </div>
          <div className={classes['details-wrapper']}>
            <div className={classes['content-wrapper']}>
              <TileDetails
                card={card}
                page={page}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
});

export default Tile;
