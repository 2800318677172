import TrendsContainer from 'components/trends-v2/TrendsContainer';
import {
  CONFIGURE_VIEW,
  KPI_UPDATE_MESSAGE,
  GUIDELINE_TEXT,
  VIEW,
  VIEW_TEXT,
} from 'constants/constants';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import classes from './ConfigureMyViewLayout.module.scss';

const ConfigureMyViewLayout = (props) => {
  const { page, view, data } = props;

  const [kpiTitleUpdateMessage, setKpiTitleUpdateMessage] = useState(false);

  const buttonText =
    page === CONFIGURE_VIEW.insights && view === VIEW.add
      ? VIEW_TEXT.addInsights
      : page === CONFIGURE_VIEW.insights && view === VIEW.edit
      ? VIEW_TEXT.updateInsights
      : page === CONFIGURE_VIEW.myView && view === VIEW.add
      ? VIEW_TEXT.addMyView
      : page === CONFIGURE_VIEW.myView && view === VIEW.edit
      ? VIEW_TEXT.updateMyView
      : VIEW_TEXT.addMyView;

  const tilesAvailableForDisplay = view === VIEW.edit ? '' : GUIDELINE_TEXT;

  return (
    <Modal
      {...props}
      size='xl'
      key={props?.show}
      className={`title gne-modal ${props.css}`}>
      <Modal.Header
        closeButton
        className={`modal-header border-0 `}>
        {kpiTitleUpdateMessage && (
          <label className='d-flex justify-content-center text text__small w-100 pr-2 '>
            <p className='ms-4 px-2 py-1 bg-warning bg-opacity-25 border-start-1 border-end-1'>
              <i className='icon icon__title-warning px-2 py-1'></i>
              {KPI_UPDATE_MESSAGE}
            </p>
          </label>
        )}
      </Modal.Header>

      <Modal.Body className='show-grid p-0 m-0'>
        <TrendsContainer
          buttonText={buttonText}
          isConfigureView={true}
          page={page}
          view={view}
          data={data}
          setKpiTitleUpdateMessage={setKpiTitleUpdateMessage}>
          <Modal.Title
            id='contained-modal-title-vcenter'
            className='title__bold w-100'>
            {view === 'edit' ? (
              <>
                <i className='icon icon__edit_black'></i>
                <span className={classes.editMetricTitle}>Edit Metric</span>
                <p className='d-flex inline-block title__small text__grey mb-0'>
                  Edit the metric below to update your view:
                </p>
              </>
            ) : (
              <>
                <div className='d-flex align-items-center'>
                  <i className='icon icon__configureTitle'></i>
                  <span className='title__medium'>Configure {page}</span>
                </div>
                  <p className='title__small text__grey mt-1 mb-0'>{tilesAvailableForDisplay}</p>
              </>
            )}
          </Modal.Title>
        </TrendsContainer>
      </Modal.Body>
    </Modal>
  );
};

export default ConfigureMyViewLayout;
