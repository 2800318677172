import Feedback from 'components/feedback/Feedback';
import WhatsNew from 'components/whats-new/WhatsNew';
import { useEffect, useState } from 'react';
import { AttentionSeeker } from 'react-awesome-reveal';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getNotificationsData,
  getReleaseData,
  logoutSSO,
  updateReleaseData,
} from 'store/home/home-my-view-actions';
import classes from './Header.module.scss';

import NotificationsList from 'components/home/NotificationsList';
import SystemAlertsList from 'components/home/SystemAlertsList';
import LinkButton from 'components/shared/button/LinkButton';
import {
  setNotificationssData,
  setWhatsNewDatas,
} from 'store/home/homeMyViewSlice';
import styles from './Help.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const [showFeedback, setShowFeedback] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const { roleId, unixId, id, email, name, roleIds } = useSelector(
    (state) => state.Auth.userClaimObject
  );
  const whatsNewData = useSelector((state) => state?.HomeMyView?.whatsNewData);

  const navigate = useNavigate();

  const userNameInitial = name?.charAt(0);
  let userLastName = email?.slice(0, email?.indexOf('.'));

  const handleFeedbackClick = () => {
    setShowFeedback(!showFeedback);
  };

  const handleNewClick = () => {
    setShowNew(!showNew);
  };

  const callNotificationApi = async () => {
    let data = await getNotificationsData(unixId, roleId);
    dispatch(setNotificationssData(data?.result));
    setshowNotification(true);
  };
  const getNotification = () => {
    if (showNotification) {
      setshowNotification(false);
    } else {
      callNotificationApi();
    }
  };

  const handleOnMouseLeaveFromNotification = () => {
    setshowNotification(false);
  };
  const getData = async () => {
    let data = await getReleaseData(roleId, unixId);
    dispatch(setWhatsNewDatas(data?.result));
    if (data?.result?.read === 'N') {
      setShowNew(true);
    } else {
      setShowNew(false);
    }
  };

  useEffect(() => {
    if (!whatsNewData?.data?.length) {
      getData();
    }
    // eslint-disable-next-line
  }, []);

  const handleHideFeedback = () => setShowFeedback(false);
  const handleHideNew = () => {
    setShowNew(false);
    if (whatsNewData?.read === 'N') {
      updateReleaseData(roleId, unixId);
    }
  };

  const [show, setShow] = useState(false);
  const [showUserName, setShowUserName] = useState(false);
  const [showNotification, setshowNotification] = useState(false);

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const handleOnUserNameMouseEnter = () => {
    setShowUserName(true);
  };
  const handleOnUserNameMouseLeave = () => {
    setShowUserName(false);
  };
  const logout = async () => {
    await logoutSSO(id);
    navigate('/logout');
  };

  return (
    <>
      <header className={classes['gne-header']}>
        <Row className='w-100'>
          <Col
            md={6}
            className='d-flex align-items-center'>
            <AttentionSeeker effect='pulse'>
              <div className='gene-logo-header'></div>
              <div className='divider-vertical divider__white'></div>
              <span className='title title__large title__bold'>C360</span>
            </AttentionSeeker>
          </Col>
          <Col
            md={6}
            className={classes['header-cta']}>
            <LinkButton
              className={`links ${classes.links} ${classes.cta} text text__small`}
              onClick={handleFeedbackClick}>
              <i className='icon icon__feedback'></i>
              Leave Feedback
            </LinkButton>
            <div>
              <OverlayTrigger
                show={showNotification}
                key='avatar'
                placement='bottom'
                className={`${classes.notificationContainer}`}
                overlay={
                  <Popover
                    id='popover-contained'
                    className={`${classes.popover}`}
                    onMouseLeave={handleOnMouseLeaveFromNotification}>
                    <Popover.Body>
                      <div className='animate__animated animate__fadeIn d-flex '>
                        <i className='icon icon__notification-bell-black mt-1' />
                        <p className='title__normal title__bold d-flex'>
                          Alerts & Notifications
                        </p>
                      </div>
                      <div className='gne-container'>
                        <Tabs
                          id='systemAlerts'
                          defaultActiveKey='notifications'
                          unmountOnExit={true}
                          mountOnEnter={true}
                          className='mb-3'>
                          <Tab
                            eventKey='notifications'
                            title={<span>Notifications</span>}>
                            <NotificationsList />
                          </Tab>
                          <Tab
                            eventKey='systemAlerts'
                            title={<span>System Alerts</span>}>
                            <SystemAlertsList viewName='header' />
                          </Tab>
                        </Tabs>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
                popperConfig={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: ({ popper, reference }) => {
                          const offsetX = -75;
                          const offsetY = 5;
                          return [offsetX, offsetY];
                        },
                      },
                    },
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['top', 'right', 'left'],
                      },
                    },
                  ],
                }}>
                <div
                  onClick={getNotification}
                  className={`d-flex mt-2 ${classes.alignment}`}
                  role='button'>
                  <i className='icon icon__notification-bell' />
                  <i className='icon icon__chevron-down-white mt-0' />
                </div>
              </OverlayTrigger>
            </div>
            <OverlayTrigger
              show={show}
              key='bottom'
              placement='bottom'
              overlay={
                <Popover
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                  id='popover-contained'>
                  <Popover.Body>
                    <div className='animate__animated animate__fadeIn'>
                      <div
                        className={styles['menu-item']}
                        onClick={handleNewClick}>
                        <i className='icon icon__whats-new'></i>
                        <span className='title title__small'>What's New</span>
                      </div>

                      <Link
                        to='https://docs.google.com/presentation/d/1RFUT_Lq6HVAo2epslyQGaPnTFtxgnGau7ITDIA-E5TY/edit#slide=id.g25c7b48c481_1_1530'
                        target='_blank'>
                        <div className={styles['menu-item']}>
                          <i className='icon icon__userGuide-blue'></i>
                          <span className='title title__small'>User Guide</span>
                        </div>
                      </Link>
                      <Link
                        to='https://drive.google.com/drive/folders/12Xn-m8aZpTnnYYy4NLMzm_QvEN1NNbu-'
                        target='_blank'>
                        <div className={styles['menu-item']}>
                          <i className='icon icon__howTo-Videos'></i>
                          <span className='title title__small'>
                            How-to Videos
                          </span>
                        </div>
                      </Link>

                      <Link
                        to='https://roche.service-now.com/teamzones?id=sc_cat_item&sys_id=9ed2999087b72114e5e440c30cbb3546&sysparm_category=7536ffc0876f69144f9432a90cbb35f0&tz_id=e0267b80876f69144f9432a90cbb35b4'
                        target='_blank'>
                        <div className={styles['menu-item']}>
                          <i className='icon icon__raise-request'></i>
                          <span className='title title__small'>
                            Raise Request
                          </span>
                        </div>
                      </Link>
                    </div>
                  </Popover.Body>
                </Popover>
              }>
              <div
                className={`d-flex mt-2 ${classes.alignment}`}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                role='button'>
                <i className='icon icon__help'></i>
                <span className='text text__small'>Help</span>
                <i className='icon icon__chevron-down-white mt-0' />
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              show={showUserName}
              key='avatar'
              placement='bottom'
              overlay={
                <Popover
                  onMouseEnter={handleOnUserNameMouseEnter}
                  onMouseLeave={handleOnUserNameMouseLeave}
                  id='popover-contained'>
                  <Popover.Body>
                    <div className={classes['userNameInitialPosition']}>
                      <span
                        className={classes['circleForUserNameInitialInPopUp']}>
                        {userNameInitial}
                      </span>
                    </div>
                    <div className={classes['userNamePosition']}>
                      <span className='title title__small title__capitalize m-0 pt-0'>
                        {name + ' ' + userLastName}
                      </span>
                      <br />
                      <span
                        className={`title title__small title__capitalize m-0 pt-0 ${classes['roleCode']}`}>
                        {roleId}
                      </span>
                    </div>
                    <div className='animate__animated animate__fadeIn mt-3'>
                      <div className='divider-horizontal mb-0'></div>
                      {roleIds?.length > 1 && (
                        <Link to='/roleSelection'>
                          <div className={styles['menu-item']}>
                            <i className='icon icon__switchRoles'></i>
                            <span className='title title__small'>
                              Switch Roles
                            </span>
                          </div>
                        </Link>
                      )}
                      <Link onClick={logout}>
                        <div className={styles['menu-item']}>
                          <i className='icon icon__logout'></i>
                          <span className='title title__small'>Sign out</span>
                        </div>
                      </Link>
                    </div>
                  </Popover.Body>
                </Popover>
              }>
              <div
                className='d-flex mt-1'
                onMouseEnter={handleOnUserNameMouseEnter}
                onMouseLeave={handleOnUserNameMouseLeave}
                role='button'>
                <span
                  className={`text text__small m-0 p-0 text__bold ${classes.circleForUserNameInitialOnHeader}`}>
                  {userNameInitial}
                </span>

                <i className='icon icon__chevron-down-white' />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </header>
      <Feedback
        show={showFeedback}
        onHide={handleHideFeedback}
      />
      <WhatsNew
        show={showNew}
        onHide={handleHideNew}
      />
    </>
  );
};

export default Header;
