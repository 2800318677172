/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChartAndTableView from './ChartAndTableView';

import {
  BENCHMARK_LEGEND_FIRST,
  CHART_ADD_MESSAGE,
  CHART_UPDATE_MESSAGE,
  COMPARE_BENCHMARK,
  COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE,
  COMPARE_KPI,
  COMPARE_TIME_PERIOD,
  DIMENSION,
  DIMENSION_NAME,
  INSIGHTS_CHART_LIMIT_ERROR,
  PRODUCT_CONFIG,
} from 'constants/constants';
import { Fade } from 'react-awesome-reveal';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  setAdvanceDropdownOptions,
  setAdvanceOptions,
  setBenchmarkNoDimensionValue,
  setChartData,
  setChartType,
  setCustomerTypes,
  setDefaultTimePeriod,
  setDimensionOptions,
  setEcosystem,
  setEcosystemOptions,
  setExpression,
  setKPIclickedStatus,
  setKpiOptions,
  setLocalFilters,
  setLocalFiltersData,
  setNoDimensionValue,
  setOldChartType,
  setProductOptions,
  setSelectedAppId,
  setSelectedAppLink,
  setSelectedCardView,
  setSelectedDimension,
  setSelectedEcosystem,
  setSelectedKPI,
  setSelectedProduct,
  setSelectedSquad,
  setSelectedTimePeriod,
  setSquadOptions,
  setTimePeriodOptions,
  setTimePeriods,
  setTimespan,
} from 'store/trends_v2/trendsSlice';

import { addToMyView, updateMyView } from 'store/home/home-my-view-actions';

import classes from '../home/configure-my-view/ConfigureMyViewLayout.module.scss';

import {
  buildAdvancePropsObject,
  ConvertSingleMultiSelectValue,
  createDynamicExp,
  getAdvanceFilterValue,
  getArrayValues,
  getChartTypeByDimension,
  selectedLocalfilter,
} from 'utils/utils';

import {
  CHART_LIMIT_ERROR,
  CONFIGURE_VIEW,
  EXPRESSION_PLACEHOLDER_TEXT,
  FILTER_PLACE_HOLDER,
  VIEW,
} from 'constants/constants';

import { getSelectedDimension } from 'components/customer/view/utils';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  getDimensionsTimePeriodOptions,
  getMultiDimensionChartData,
  getTimePeriodOptions,
} from 'services/trends.service';
import { getCustomerExpr, isAllselected, isArray } from 'utils/utils';

import KPIFilters from './KPIFilters';
import KpiMapping from './KpiMapping';
import TileName from './TileName';

import { isEmpty, isNull, isUndefined } from 'lodash';
import { getCustomerData } from 'store/customer/customer-view-actions';
import {
  setAdvanceSearchSelectedData,
  setAllCustomers,
  setCards as setCardsInsight,
  setCustomerViewButtonDisabled,
  setFocusAccountChecked,
  setFocusAccountDisabled,
  setCards as setInsightCards,
  setPriorityCustomers,
  setSelectedCustomer,
} from 'store/customer/customerViewSlice';
import { setCards } from 'store/home/homeMyViewSlice';
import {
  getAllKpiList,
  getEcosystemData,
  getEcosystems,
  getKPIProps,
  GetProductAPI,
  GetSquatAPI,
} from 'store/trends_v2/trendsAction';

export default function TrendsContainer({
  buttonText,
  data,
  view,
  page,
  setKpiTitleUpdateMessage,
  children,
}) {
  const reducer =
    page === CONFIGURE_VIEW.insights ? 'CustomerView' : 'HomeMyView';

  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();
  const filterExpression = useRef('');
  /*
   Remove the commented code post testing
  */
  // const [disableKPI, setDisableKPI] = useState(false);
  const [expressionToSave, setExpressionToSave] = useState('');

  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);

  const focusCheck = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );

  /*
   Remove the commented code post testing
  */
  // const prevFocusedCustomerRef = useRef('');

  const {
    priorityCustomers,
    // noFocusedAccount,
    allCustomers,
    selectedCustomer,
  } = useSelector((state) => state?.CustomerView);

  const localAccount = useRef(priorityCustomers);

  const {
    selectedCardView,
    localFiltersData,
    expression,
    timespan,
    localFilters,
    customerTypes,
  } = useSelector((state) => state?.trends);

  const { options: squadOptions, value: selectedSquad } = useSelector(
    (state) => state.trends.KPIFilter.Squad
  );

  const { options: ecosystemOptions, value: selectedEcosystem } = useSelector(
    (state) => state.trends.KPIFilter.Ecosystem
  );

  const localEcosystem = useRef(selectedEcosystem);

  const { options: allProductOptions, value: selectedProduct } = useSelector(
    (state) => state.trends.KPIFilter.Product
  );
  const accountableProductOptions = allProductOptions?.filter(
    (product) => product?.priorityFlag === 'Y'
  );

  const { value: selectedTimePeriod } = useSelector(
    (state) => state.trends.KPIFilter.TimePeriod
  );
  const timePeriods = useSelector((state) => state?.trends?.timePeriod);
  const cards = useSelector((state) => state[reducer]?.cards);
  const checkApplyBtnStatus = useSelector(
    (state) => state?.trends?.applyBtnStatusForProdAndSquad
  );

  const {
    selectedKPI,
    selectedDimension,
    dimensionOptions,
    selectedAppId,
    chartData,
    chartType,
    oldChartType,
    selectedAppLink,
    noDimensionValue,
  } = useSelector((state) => state?.trends);

  const selectedAdvanceOptions = useSelector(
    (state) => state?.trends?.advanceOptions
  );

  const [editFirstLoad, setEditFirstLoad] = useState(false);
  const advanceComparePropsRef = useRef('');
  const priorityRef = useRef(true);
  const cardViewTypeEditView = useRef('');
  const localSelectedSquad = useRef([]);
  const localSelectedProduct = useRef([]);
  const [filterValidation, setFilterValidation] = useState(true);
  const vReplaceStringForDynamicFilter = useRef('');

  const defaultKPIName =
    selectedDimension?.dimensionName === 'No Dimension'
      ? selectedKPI?.KpiName
      : selectedKPI?.KpiName + ` by ${selectedDimension?.dimensionName}`;

  const [updatedKpiName, setUpdatedKpiName] = useState(
    data?.title ? data?.title : defaultKPIName
  );

  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const userRestrictedEcosystemt = useSelector(
    (state) => state?.Auth?.userRestrictedEcosystemt
  );
  const restrictedProductSquad = useSelector(
    (state) => state?.Auth?.restrictedProductSquad
  );
  const [accountableProductsChecked, setAccountableProductsChecked] = useState(
    userRestrictedRole?.productRestrictions === 'Y' ? false : true
  );

  const [enableApplyDisableAddtoButton, setEnableApplyDisableAddtoButton] =
    useState(true);
  var applyButton =
    enableApplyDisableAddtoButton ||
    (PRODUCT_CONFIG.MARKET_SHARE === selectedKPI?.productConfig &&
      selectedProduct?.length === 0 &&
      selectedDimension?.dimensionName !== DIMENSION_NAME.PRODUCT);
  const handleEnableApplyBtn = () => {
    setEnableApplyDisableAddtoButton(false);
    setFilterValidation(
      selectedDimension?.filterSingleSelect?.productSingleSelect === 'Y'
        ? selectedProduct?.length > 0
          ? true
          : false
        : true
    );
  };
  useEffect(() => {
    handleEnableApplyBtn();
  }, [
    selectedCustomer,
    selectedEcosystem,
    selectedTimePeriod,
    accountableProductsChecked,
    focusCheck,
    selectedKPI,
    selectedDimension,
    localFiltersData,
  ]);

  useEffect(() => {
    if (checkApplyBtnStatus) {
      handleEnableApplyBtn();
    }
  }, [selectedProduct, selectedSquad]);

  // Handle Add Edit flow for first render
  useEffect(() => {
    (async () => {
      try {
        if (view === 'edit') {
          // setDisableKPI(false);
          setKpiTitleUpdateMessage(false);
          // Get Data From Card
          const {
            filter,
            expression,
            advanceEnabled,
            advanceFilterValue,
            advanceType,
            cardView,
          } = data;
          // Get Selected Global filter data from Card
          const {
            selectedAccount,
            selectedProduct = [],
            selectedTimePeriod,
            focusAccountChecked,
            selectedEcosystem = [],
            accountableProduct,
            selectedSquad = [],
            viewType,
            selectedKPI,
            chartType,
          } = filter;

          const dimensionName = selectedKPI?.dimensions[0]?.dimensionName;

          // Advance options
          if (advanceEnabled === 'Y') {
            advanceComparePropsRef.current = buildAdvancePropsObject(
              advanceFilterValue,
              advanceType,
              dimensionName,
              chartType
            );
          } else {
            advanceComparePropsRef.current = '';
          }
          dispatch(setAdvanceOptions(advanceComparePropsRef.current));
          dispatch(setExpression(expression));
          // Set Chart Type
          cardViewTypeEditView.current = viewType;
          dispatch(setSelectedCardView(cardView));

          // Set Ecosystem
          if (page === CONFIGURE_VIEW.insights) {
            dispatch(getEcosystemData(unixId, roleId, selectedCustomer?.value));
          } else {
            if (userRestrictedRole?.isNational === 'N') {
              dispatch(setEcosystemOptions(userRestrictedEcosystemt));
            } else if (ecosystemOptions && ecosystemOptions?.length === 0) {
              dispatch(getEcosystemData(unixId, roleId));
            }
          }
          localEcosystem.current = selectedEcosystem;
          priorityRef.current = focusAccountChecked;
          dispatch(setSelectedEcosystem(selectedEcosystem));

          dispatch(setFocusAccountChecked(focusAccountChecked));

          let flag;
          if (page === CONFIGURE_VIEW.insights) {
            flag = selectedCustomer.flag;
            dispatch(setFocusAccountChecked(focusCheck));
          }

          // Call KPI API
          const result = await getAllKpiList(roleId, flag);

          dispatch(setKpiOptions(result));
          /*Handling Edit scenario to check product config is available or not
           if not then assign the product config
           */
          isUndefined(selectedKPI?.productConfig)
            ? dispatch(
                setSelectedKPI({
                  ...selectedKPI,
                  productConfig: result?.find(
                    (kpi) => kpi?.KpiId === selectedKPI?.KpiId
                  )?.productConfig,
                })
              )
            : dispatch(setSelectedKPI(selectedKPI));

          dispatch(setSelectedAppId(selectedKPI?.AppId));
          dispatch(setSelectedAppLink(selectedKPI?.AppLink));

          // Call KPI Props
          const kpiPropsResult = await getKPIProps(selectedKPI?.KpiId);
          const { dimensions, customerType, localFilter, timePeriod } =
            kpiPropsResult;

          // Get Default Dimension
          dispatch(setCustomerTypes(customerType));
          dispatch(setLocalFilters(localFilter));
          dispatch(setDimensionOptions(dimensions));
          let selectedDimension = getSelectedDimension(
            dimensions,
            dimensionName
          );

          // Set Chart Type
          dispatch(setChartType(chartType));
          dispatch(
            setOldChartType(getChartTypeByDimension(dimensions, dimensionName))
          );

          let isDimensionLevelExpression =
            selectedDimension?.filterEnable?.EnableDimensionLevelExpression ===
            'Y';

          // Set Time Perios
          const timePeriodOptions = isDimensionLevelExpression
            ? await getDimensionsTimePeriodOptions(
                timePeriod,
                selectedDimension?.dimensionId
              )
            : await getTimePeriodOptions(timePeriod);

          dispatch(setTimePeriods(timePeriod));
          dispatch(setTimePeriodOptions(timePeriodOptions));
          dispatch(setSelectedTimePeriod(selectedTimePeriod));

          let exp = timePeriod.filter((tp) => tp.uXDefaultValue === 'Y')[0];
          dispatch(
            setDefaultTimePeriod({
              label: exp.timePeriodName,
              value: exp.timePeriodId,
            })
          );

          // Product
          await loadProductOptions();
          let checkedProducts =
            selectedProduct === 'All'
              ? accountableProduct
                ? accountableProductOptions
                : allProductOptions
              : selectedProduct;

          localSelectedProduct.current = selectedProduct;
          dispatch(setSelectedProduct(checkedProducts));
          setAccountableProductsChecked(accountableProduct);

          // Squad
          await loadSquadOptions();
          let checkedSquads =
            selectedSquad === 'All' ? squadOptions : selectedSquad;
          dispatch(setSelectedSquad(checkedSquads ? checkedSquads : []));
          localSelectedSquad.current = checkedSquads ? checkedSquads : [];

          // Customer
          if (page !== CONFIGURE_VIEW.insights)
            dispatch(setSelectedCustomer(selectedAccount));

          // Set selected Local filters
          let localFields = {};
          data?.localFilters?.forEach((element) => {
            let field = data?.kpiFilters[`selected${element.filterName}`];
            if (field) {
              localFields = {
                ...localFields,
                [element.qExprClm]: {
                  value: ConvertSingleMultiSelectValue(field),
                  options: [],
                },
              };
            } else {
              localFields = {
                ...localFields,
                [element.qExprClm]: {
                  value: [],
                  options: [],
                },
              };
            }
          });
          dispatch(
            setLocalFiltersData({
              ...localFields,
            })
          );

          // Set Dimension
          dispatch(setSelectedDimension(selectedDimension));

          // Get Customer Data in Next UseEffect once we have KPI and Dimension selected in state for first render
        } else {
          // Add Flow
          // setDisableKPI(false);
          advanceComparePropsRef.current = '';
          dispatch(setAdvanceOptions(advanceComparePropsRef.current));
          dispatch(setSelectedCardView('chart'));

          let flag;
          if (page === CONFIGURE_VIEW.insights) {
            flag = selectedCustomer.flag;
            dispatch(setFocusAccountChecked(focusCheck));
          }

          // Call KPI API
          const result = await getAllKpiList(roleId, flag);

          // Get Default KPI
          const defaultKPI = result ? result[0] : null;
          dispatch(setKpiOptions(result));
          dispatch(setSelectedKPI(defaultKPI));
          dispatch(setSelectedAppId(defaultKPI?.AppId));
          dispatch(setSelectedAppLink(defaultKPI?.AppLink));

          // Call KPI Props
          const kpiPropsResult = await getKPIProps(defaultKPI?.KpiId);
          const { dimensions, customerType, localFilter, timePeriod } =
            kpiPropsResult;

          // Get Default Dimension
          let defaultDimension = dimensions ? dimensions[0] : null;

          dispatch(setCustomerTypes(customerType));
          dispatch(setLocalFilters(localFilter));
          dispatch(setDimensionOptions(dimensions));

          // Time Period
          let isDimensionLevelExpression =
            defaultDimension?.filterEnable?.EnableDimensionLevelExpression ===
            'Y';
          const timePeriodOptions = isDimensionLevelExpression
            ? await getDimensionsTimePeriodOptions(
                timePeriod,
                defaultDimension?.dimensionId
              )
            : await getTimePeriodOptions(timePeriod);

          let exp = timePeriod.filter((tp) => tp.uXDefaultValue === 'Y')[0];

          dispatch(setTimePeriods(timePeriod));
          dispatch(setTimePeriodOptions(timePeriodOptions));

          dispatch(
            setSelectedTimePeriod({
              label: exp.timePeriodName,
              value: exp.timePeriodId,
            })
          );
          dispatch(setTimespan(exp?.timeSpan));
          dispatch(
            setDefaultTimePeriod({
              label: exp.timePeriodName,
              value: exp.timePeriodId,
            })
          );

          // Set Ecosystem
          if (page === CONFIGURE_VIEW.insights) {
            dispatch(getEcosystemData(unixId, roleId, selectedCustomer?.value));
          } else {
            if (userRestrictedRole?.isNational === 'N') {
              dispatch(setEcosystemOptions(userRestrictedEcosystemt));
            } else {
              // dispatch(getEcosystemData(unixId, roleId));
            }
          }

          // Not Needed in Add flow.
          // // Process Product
          // await loadProductOptions();

          // // Process Squad
          // await loadSquadOptions();

          dispatch(setSelectedDimension(defaultDimension));

          // Get Customer Data in Next UseEffect once we have KPI and Dimension selected in state for first render
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const getEcosystem = () => {
    const selectedEcosystemLabels =
      getArrayValues(selectedEcosystem).split(',');

    if (
      (selectedEcosystem?.length > 0 && !focusCheck) ||
      selectedDimension?.filterEnable?.ecosystemEnable === 'N'
    ) {
      return selectedEcosystemLabels;
    }
    return [];
  };

  const userEcosystem = [CONFIGURE_VIEW.trends, CONFIGURE_VIEW.myView].includes(
    page
  );

  const GetCustomerList = async (customerType) => {
    try {
      const ecosystems = getEcosystem();

      const response = await getCustomerData(
        true,
        '',
        0,
        roleId,
        unixId,
        false,
        undefined,
        customerType,
        undefined,
        undefined,
        undefined,
        userEcosystem
      );

      const { data } = response.result;

      dispatch(setPriorityCustomers(data));
      /* 
        Remove the commented code post testing
      */
      // if (data.length === 0) {
      //   dispatch(setFocusAccountChecked(false));
      //   dispatch(setFocusAccountDisabled(true));
      // }

      return data;
    } catch (error) {
      console.error('Error loading dropdown options:', error);
    }
  };

  const firstRender = useRef(true);
  useEffect(() => {
    (async () => {
      try {
        let callApi = checkSingleSelectEligibility();
        if (selectedDimension?.dimensionId) {
          // Reset squad and product if product enable is N and disable accountableProductsChecked on every KPI change
          if (selectedDimension?.filterEnable?.productEnable === 'N') {
            setAccountableProductsChecked(false);
            dispatch(setSelectedProduct([]));
            localSelectedProduct.current = [];
            dispatch(setSelectedSquad([]));
            localSelectedSquad.current = [];
          }

          // Update the tile Name for add /edit view
          if (view === VIEW.edit) {
            const defaultKPIName = firstRender.current
              ? data?.title
              : selectedDimension?.dimensionName === 'No Dimension'
              ? selectedKPI?.KpiName
              : selectedKPI?.KpiName +
                ` by ${selectedDimension?.dimensionName}`;
            setUpdatedKpiName(defaultKPIName);
          } else {
            const defaultKPIName =
              selectedDimension?.dimensionName === 'No Dimension'
                ? selectedKPI?.KpiName
                : selectedKPI?.KpiName +
                  ` by ${selectedDimension?.dimensionName}`;
            setUpdatedKpiName(defaultKPIName);
          }

          // Check if account is not enabled for add and edit both the flow and on Change of kpi and dimension
          if (selectedDimension?.filterEnable?.accountEnable === 'N') {
            dispatch(setAdvanceSearchSelectedData({}));
            if (page !== CONFIGURE_VIEW.insights)
              dispatch(setSelectedCustomer([]));
            if (priorityRef?.current) {
              /* 
              Remove the commented code post testing
              */
              // prevFocusedCustomerRef.current = focusCheck;
              dispatch(setFocusAccountChecked(false));
              // dispatch(setNoFocusedAccount(true));
              priorityRef.current = false;
              dispatch(setSelectedEcosystem([]));
              localEcosystem.current = [];
            }
          }
          /* 
            Remove the commented code post testing
          */
          //  else if (prevFocusedCustomerRef?.current) {
          //   dispatch(setFocusAccountChecked(prevFocusedCustomerRef?.current));
          //   prevFocusedCustomerRef.current = '';
          // }

          // ONLY ON FIRST TIME RENDER, CALL CUSTOMERLIST

          if (firstRender.current) {
            firstRender.current = false;

            if (page !== CONFIGURE_VIEW.insights) {
              const priorityCustomer =
                selectedDimension?.filterEnable?.accountEnable === 'N' &&
                isNull(customerTypes)
                  ? []
                  : await GetCustomerList(customerTypes);
              dispatch(setFocusAccountDisabled(priorityCustomer?.length === 0));

              if (priorityCustomer?.length > 0) {
                let allEcosystemOptions = await getEcosystemOptions();
                dispatch(setEcosystemOptions(allEcosystemOptions));
              }
              await processAccountData(
                priorityCustomer,
                view === 'edit',
                view !== 'edit' ? false : priorityRef.current
              );
            }
            // Build Expression and call Qlick and Analytics API
            callApi = checkSingleSelectEligibility();
            if (!promiseInProgress && callApi) {
              // DO not show update mgs in edit mode for first render
              const showUpdateMgs = view === VIEW.edit ? false : true;
              await buildExpression(showUpdateMgs);
            }

            // Disable Apply button on first render
            setEnableApplyDisableAddtoButton(true);

            // Stop loader
            // setDisableKPI(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedDimension?.dimensionId]);

  //'Cleanup is in progress'
  useEffect(() => {
    return () => {
      dispatch(setLocalFiltersData({}));
      dispatch(setSelectedAppId(null));
      dispatch(setSelectedKPI(null));
      dispatch(setSelectedDimension(null));
      dispatch(setSelectedAppLink(null));
      dispatch(setOldChartType('KPI'));
      dispatch(setChartType('KPI'));
      dispatch(setAdvanceOptions(''));
      dispatch(setAdvanceDropdownOptions([]));
      dispatch(setSelectedTimePeriod([]));
      dispatch(setSelectedEcosystem([]));
      dispatch(setSelectedProduct([]));
      dispatch(setSelectedSquad([]));
      if (page === CONFIGURE_VIEW.insights) dispatch(setEcosystemOptions([]));
    };
  }, []);

  useEffect(() => {
    localSelectedSquad.current = selectedSquad;
    localSelectedProduct.current = selectedProduct;
    localEcosystem.current = selectedEcosystem;
    localAccount.current = priorityCustomers;
  }, [selectedSquad, selectedProduct, selectedEcosystem, priorityCustomers]);

  const processAccountData = async (
    accounts,
    editFlow,
    editfocusAccountChecked
  ) => {
    let accountOptions = [];

    // If priority accounts exist
    if (accounts?.length > 0) {
      dispatch(setAllCustomers(accounts));
      priorityRef.current = true;
      localAccount.current = accounts;
      dispatch(setFocusAccountChecked(true));

      if (
        (!focusCheck && !editFlow) ||
        (!editfocusAccountChecked && editFlow)
      ) {
        processEcosystemSelection(editFlow);
      } else {
        // set ecosystem to All
        // we check if the user has some selected ecosystem
        // and use that otherwise select the 1st one
        dispatch(
          setSelectedEcosystem(
            selectedEcosystem?.length > 0 ? selectedEcosystem : []
          )
        );
      }
    } else {
      // Code is added to handle the scenario when
      // there is no priority account of user
      dispatch(setFocusAccountChecked(false));
      // dispatch(setNoFocusedAccount(true));
      if (!editfocusAccountChecked && editFlow) {
        processEcosystemSelection(editFlow);
      } else {
        let allEcosystemOptions = await getEcosystemOptions();
        dispatch(setEcosystemOptions(allEcosystemOptions));
      }
    }

    if (page !== CONFIGURE_VIEW.insights) {
      if (editFlow) {
        if (selectedCustomer === 'All') {
          dispatch(setSelectedCustomer(accountOptions));
        } else {
          dispatch(setSelectedCustomer(selectedCustomer));
        }
      } else {
        dispatch(setSelectedCustomer([]));
      }
    }
  };

  const getEcosystemOptions = async () => {
    return userRestrictedRole?.isNational === 'N'
      ? userRestrictedEcosystemt
      : ecosystemOptions?.length === 0
      ? await getEcosystems(unixId, roleId)
      : ecosystemOptions;
  };

  const processEcosystemSelection = async (editFlow) => {
    dispatch(setFocusAccountChecked(false));

    let allEcosystemOptions = await getEcosystemOptions();

    if (allEcosystemOptions?.length > 0) {
      // dispatch(setEcosystemOptions(allEcosystemOptions));
      // we check if the user has some selected ecosystem
      // and use that otherwise select the 1st one
      if (!editFlow) {
        dispatch(
          setEcosystem({
            options: allEcosystemOptions,
            value: selectedEcosystem?.length > 0 ? selectedEcosystem : [],
          })
        );
      } else {
        dispatch(setEcosystemOptions(allEcosystemOptions));
      }
    }
  };

  const loadProductOptions = async () => {
    let response = { options: [], hasMore: false };
    if (page === CONFIGURE_VIEW.insights) {
      response = await GetProductAPI('', selectedSquad);
    } else {
      response = await GetProductAPI(
        '',
        selectedSquad,
        userRestrictedRole,
        unixId,
        roleId
      );
    }
    if (selectedProduct?.length === 0 && view !== 'edit') {
      dispatch(setSelectedProduct([]));
    }

    const accountableProductOptions = response?.data?.result?.filter(
      (product) => product?.priorityFlag === 'Y'
    );

    const allProductOptions = response?.data?.result;

    if (
      accountableProductsChecked &&
      localSelectedProduct?.current?.length > 0
    ) {
      const selectedAccountableProducts = localSelectedProduct.current.filter(
        (selection) =>
          accountableProductOptions.find(
            (item) => item.label === selection.label
          )
      );

      dispatch(setSelectedProduct(selectedAccountableProducts));
    }

    const allProducts = accountableProductsChecked
      ? accountableProductOptions
      : allProductOptions;

    dispatch(setProductOptions(allProducts));
    return {
      options: allProducts,
    };
  };

  const loadSquadOptions = async () => {
    let response = { options: [], hasMore: false };
    if (page === CONFIGURE_VIEW.insights) {
      response = await GetSquatAPI('', selectedProduct);
    } else {
      response = await GetSquatAPI(
        '',
        selectedProduct,
        userRestrictedRole,
        unixId,
        roleId
      );
    }

    dispatch(setSquadOptions(response.options));

    if (selectedSquad?.length === 0 && view !== VIEW.edit) {
      dispatch(setSelectedSquad([]));
    } else if (selectedProduct?.length > 0 && selectedSquad?.length !== 0) {
      dispatch(setSelectedSquad(response.options));
    }

    return {
      options: response.options,
      hasMore: response.hasMore,
    };
  };

  const handleProductType = (value) => {
    setAccountableProductsChecked(value?.target?.checked);
    dispatch(setSelectedProduct([]));
  };

  useEffect(() => {
    // Keep loal priority ref updated
    if (!(firstRender.current && view === VIEW.edit)) {
      priorityRef.current = focusCheck;
      // handle ecosystem dependency on priority checked unchecked
      accountFocusAction(focusCheck);
    }
    //Enable/Disable priority checkbox
    priorityCustomers?.length > 0
      ? dispatch(setFocusAccountDisabled(false))
      : dispatch(setFocusAccountDisabled(true));
  }, [focusCheck]);

  const accountFocusAction = (value) => {
    if (page !== CONFIGURE_VIEW.insights) dispatch(setSelectedCustomer([]));

    // setPrevFocusedAccountState(value);
    if (isArray(ecosystemOptions)) {
      if (!value) {
        dispatch(setSelectedEcosystem([]));
      } else {
        setFocusedAccount();
      }
    }
  };

  const setFocusedAccount = async () => {
    if (selectedEcosystem?.length !== 0) {
      dispatch(setSelectedEcosystem([]));
    }
    localAccount.current = [...priorityCustomers];
    dispatch(setAllCustomers(priorityCustomers));
  };

  const buildExpression = async (showUpdateMgs = true) => {
    try {
      // Build Expression for Customer View Insight Board
      if (page === CONFIGURE_VIEW.insights) {
        buildExpressionInsight(showUpdateMgs);
        return;
        // DO not execute below code for Insight
      }

      // let isAllEco = focusCheck;
      let isPriority = focusCheck;

      // if (
      //   !noFocusedAccount &&
      //   selectedDimension?.filterEnable?.accountEnable === 'Y' &&
      //   !isAllEco &&
      //   prevFocusAccountChecked &&
      //   view !== VIEW.edit
      // ) {
      //   dispatch(setFocusAccountChecked(true));
      //   isPriority = true;
      //   isAllEco = true;
      //   await setFocusedAccount();
      // }
      const allPriorityAccounts =
        isArray(localAccount.current) &&
        isPriority &&
        selectedCustomer?.length === 0;

      const accountExpression =
        selectedDimension?.filterEnable?.accountEnable === 'Y'
          ? allPriorityAccounts
            ? getCustomerExpr(
                localAccount.current,
                isArray(localAccount.current)
              )
            : selectedCustomer?.length > 0
            ? getCustomerExpr(selectedCustomer, isArray(selectedCustomer))
            : undefined
          : undefined;

      const productExpression =
        userRestrictedRole?.productRestrictions === 'Y' &&
        selectedProduct?.length === 0
          ? 'Product={"' +
            restrictedProductSquad?.map((a) => a?.product).join('","') +
            '"}'
          : selectedProduct?.length > 0
          ? 'Product={"' +
            selectedProduct?.map((a) => a?.label).join('","') +
            '"}'
          : undefined;
      const squadExpression =
        userRestrictedRole?.squadRestriction === 'Y' &&
        selectedSquad?.length === 0
          ? 'Squad={"' +
            restrictedProductSquad?.map((a) => a?.squad).join('","') +
            '"}'
          : selectedSquad?.length > 0
          ? 'Squad={"' + selectedSquad?.map((a) => a?.label).join('","') + '"}'
          : undefined;

      let dynamicLocalFilterExp = createDynamicExp(localFiltersData);

      const finalExp = [dynamicLocalFilterExp].filter(Boolean).join(',');

      let ecosystemExpression;
      if (view === VIEW.edit) {
        ecosystemExpression =
          !focusCheck &&
          userRestrictedRole?.isNational === 'N' &&
          selectedEcosystem?.length === 0
            ? 'Ecosystem={"' +
              userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
              '"}'
            : !focusCheck && selectedEcosystem?.length > 0
            ? 'Ecosystem={"' +
              selectedEcosystem?.map((a) => a?.label).join('","') +
              '"}'
            : focusCheck &&
              accountExpression === undefined &&
              userRestrictedRole?.isNational === 'N'
            ? 'Ecosystem={"' +
              userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
              '"}'
            : undefined;
      } else {
        ecosystemExpression =
          !priorityRef?.current &&
          userRestrictedRole?.isNational === 'N' &&
          localEcosystem?.current?.length === 0
            ? 'Ecosystem={"' +
              userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
              '"}'
            : !priorityRef?.current && localEcosystem?.current?.length > 0
            ? 'Ecosystem={"' +
              localEcosystem?.current?.map((a) => a?.label).join('","') +
              '"}'
            : undefined;
      }
      const isAccountableProductExpression =
        accountableProductsChecked && `isAccountableProduct={"Y"}`;

      let finalExpression = '';

      if (accountExpression !== undefined)
        finalExpression = finalExpression.concat(`${accountExpression},`);
      if (squadExpression !== undefined)
        finalExpression = finalExpression.concat(`${squadExpression},`);
      if (productExpression !== undefined)
        finalExpression = finalExpression.concat(`${productExpression},`);
      if (ecosystemExpression !== undefined)
        finalExpression = finalExpression.concat(`${ecosystemExpression},`);
      if (isAccountableProductExpression)
        finalExpression = finalExpression.concat(
          `${isAccountableProductExpression},`
        );
      if (finalExp !== '')
        finalExpression = finalExpression.concat(`${finalExp} `);

      finalExpression =
        finalExpression.charAt(finalExpression?.length - 1) === ','
          ? finalExpression?.slice(0, -1)
          : finalExpression;

      // Save filter to use for advance expression
      filterExpression.current = finalExpression;

      //Building Expression to Save and will dynamicall populate at Home Page.
      const finalExpressionWithVReplaceToSave = `vReplaceString`;

      // put it together and store it
      const filters = [
        { finalExpression, time: selectedTimePeriod?.label },
        { finalExpressionWithVReplaceToSave, time: selectedTimePeriod?.label },
      ];
      vReplaceStringForDynamicFilter.current =
        finalExpressionWithVReplaceToSave;

      // we want the expression. It is not stored in timePeriods
      // so filter to get the expression for the time period
      // that is selected
      let isDimensionLevelExpression =
        selectedDimension?.filterEnable?.EnableDimensionLevelExpression === 'Y';

      const timePeriod = timePeriods?.filter((t) =>
        isDimensionLevelExpression
          ? t.dimensionId === selectedDimension?.dimensionId &&
            t.timePeriodName === filters[0].time
          : t.timePeriodName === filters[0].time
      );

      const expression = timePeriod?.[0]?.expression.replaceAll(
        EXPRESSION_PLACEHOLDER_TEXT,
        filters[0].finalExpression ? filters[0].finalExpression : 'test'
      );
      dispatch(setExpression(expression));

      if (!editFirstLoad) {
        dispatch(setSelectedAppId(selectedKPI?.AppId));
        setEditFirstLoad(true);
      }

      // replace the placeholder string with our filters
      const expressionToSave = timePeriod?.[0]?.expression.replaceAll(
        EXPRESSION_PLACEHOLDER_TEXT,
        filters[1].finalExpressionWithVReplaceToSave
      );

      setExpressionToSave(expressionToSave);
      dispatch(setTimespan(timePeriod[0]?.timeSpan));

      if (view === VIEW.edit) {
        generateMultiDimensionObject(
          advanceComparePropsRef.current,
          expression,
          oldChartType,
          selectedAppId,
          selectedTimePeriod
        );
      } else {
        // Call api on apply
        if (checkSingleSelectEligibility()) {
          generateMultiDimensionObject(
            advanceComparePropsRef.current,
            expression,
            oldChartType
          );
        }
      }

      setEnableApplyDisableAddtoButton(true);

      if (view === VIEW.edit && showUpdateMgs) setKpiTitleUpdateMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const buildExpressionInsight = (showUpdateMgs) => {
    try {
      // Build Expression for Account
      const accountExp = getCustomerExpr(
        selectedCustomer,
        isArray(selectedCustomer)
      );

      // Build Expression for Global FIlters
      const productExp =
        isArray(selectedProduct) && selectedProduct?.length > 0
          ? 'Product={"' +
            selectedProduct.map((a) => a.label).join('","') +
            '"}'
          : undefined;

      const suqadExp =
        isArray(selectedSquad) && selectedSquad?.length > 0
          ? 'Squad={"' + selectedSquad.map((a) => a.label).join('","') + '"}'
          : undefined;

      const ecosystemExp =
        isArray(selectedEcosystem) && selectedEcosystem?.length > 0
          ? 'Ecosystem={"' +
            selectedEcosystem.map((a) => a.label).join('","') +
            '"}'
          : undefined;

      // Buid Expression for local filters
      let dynamicLocalFilterExp = createDynamicExp(localFiltersData);
      const finalExp = [dynamicLocalFilterExp].filter(Boolean).join(',');

      // Combine all expression
      let finalExpression = '';
      if (accountExp) finalExpression = `${accountExp},`;
      if (productExp) finalExpression = `${finalExpression}${productExp},`;
      if (suqadExp) finalExpression = `${finalExpression}${suqadExp},`;
      if (ecosystemExp) finalExpression = `${finalExpression}${ecosystemExp},`;
      if (finalExp) finalExpression = `${finalExpression}${finalExp},`;
      if (accountableProductsChecked)
        finalExpression = `${finalExpression} isAccountableProduct={"Y"}`;

      finalExpression =
        finalExpression.charAt(finalExpression?.length - 1) === ','
          ? finalExpression?.slice(0, -1)
          : finalExpression;

      // Save filter to use for advance expression
      filterExpression.current = finalExpression;

      //Building Expression to Save and will dynamicall populate at Home Page.
      const finalExpressionWithVReplaceToSave = `vReplaceString`;

      // Put it together and store it
      const filters = [
        { finalExpression, time: selectedTimePeriod?.label },
        { finalExpressionWithVReplaceToSave, time: selectedTimePeriod?.label },
      ];

      vReplaceStringForDynamicFilter.current =
        finalExpressionWithVReplaceToSave;

      let isDimensionLevelExpression =
        selectedDimension?.filterEnable?.EnableDimensionLevelExpression === 'Y';

      const timePeriod = timePeriods?.filter((t) =>
        isDimensionLevelExpression
          ? t.dimensionId === selectedDimension?.dimensionId &&
            t.timePeriodName === filters[0].time
          : t.timePeriodName === filters[0].time
      );

      // Replace the placeholder string with our filters
      const expression = timePeriod?.[0]?.expression.replaceAll(
        EXPRESSION_PLACEHOLDER_TEXT,
        filters?.[0]?.finalExpression ? filters?.[0]?.finalExpression : 'test'
      );

      dispatch(setExpression(expression));

      // replace the placeholder string with our filters
      const expressionToSave = timePeriod?.[0]?.expression.replaceAll(
        EXPRESSION_PLACEHOLDER_TEXT,
        filters[1].finalExpressionWithVReplaceToSave
      );

      setExpressionToSave(expressionToSave);
      dispatch(setTimespan(timePeriod[0]?.timeSpan));

      if (view === VIEW.edit) {
        if (!editFirstLoad) {
          setEditFirstLoad(true);
        }
        generateMultiDimensionObject(
          advanceComparePropsRef.current,
          expression,
          oldChartType,
          selectedAppId,
          selectedTimePeriod,
          selectedDimension
        );
      } else {
        if (checkSingleSelectEligibility()) {
          generateMultiDimensionObject(
            advanceComparePropsRef.current,
            expression,
            oldChartType
          );
        }
      }
      setEnableApplyDisableAddtoButton(true);

      if (view === VIEW.edit && showUpdateMgs) setKpiTitleUpdateMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const addToinsights = async () => {
    const filteredCards = cards.filter((item) => item?.isDeleted !== true);

    if (filteredCards?.length >= 12) {
      toast.error(INSIGHTS_CHART_LIMIT_ERROR);
      return;
    }
    const positionIndex = filteredCards?.length + 1;
    const product = isAllselected(
      localSelectedProduct.current,
      allProductOptions
    );

    const cardData = {
      cardId: null,
      title: updatedKpiName,
      custInsightsBrdId: null,
      positionIndex: positionIndex,
      cardView: selectedCardView,
      kpiId: selectedKPI?.KpiId,
      filter: {
        selectedAccount: selectedCustomer,
        selectedEcosystem,
        selectedProduct: product,
        selectedTimePeriod,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad?.current,
      },
      kpiFilters: selectedLocalfilter(localFilters, localFiltersData),
      expression: expressionToSave,
      appId: selectedAppId,
      chartData: chartData,
      timeSpan: timespan,
      selectedAppLink: selectedAppLink,

      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,

      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',

      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,

      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,

      advanceFilterValue: getAdvanceFilterValue(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),

      // Add Local filters in card to get local filters in edit mode if board is not saved yet.
      localFilters: localFilters,
    };

    dispatch(setInsightCards([...cards, cardData]));
    dispatch(setCustomerViewButtonDisabled(false));

    toast.success(CHART_ADD_MESSAGE);
  };

  const updateView = async () => {
    if (page === CONFIGURE_VIEW.insights) {
      updateInsightView();
      return;
    }

    const account = isAllselected(selectedCustomer, allCustomers);
    const payload = {
      isAlignmentChange: 'N',
      kpiId: selectedKPI?.KpiId,
      roleId,
      cardId: data.cardId,
      title: updatedKpiName,
      cardView: selectedCardView,
      filter: {
        selectedAccount: account,
        selectedEcosystem: focusCheck
          ? []
          : selectedEcosystem?.length === ecosystemOptions.length
          ? []
          : selectedEcosystem,
        selectedProduct: localSelectedProduct.current,
        selectedTimePeriod,
        focusAccountChecked: focusCheck,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad.current,
      },
      kpiFilters: selectedLocalfilter(data.localFilters, localFiltersData),
      expression: expressionToSave,
      appId: selectedAppId,
      timeSpan: timespan,
      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,
      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',
      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,
      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,
      advanceFilterValue: getAdvanceFilterValue(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),
      // Add Local filters in card to get local filters in edit mode if board is not saved yet.
      localFilters: localFilters,
    };
    const { message } = await updateMyView(payload);
    const cardData = {
      ...payload,
      filter: payload.filter,
      selectedAppLink: selectedAppLink,
      chartData: chartData,
      positionIndex: data.positionIndex,
    };

    dispatch(
      setCards(
        cards.map((item) => {
          return item.cardId === payload.cardId ? cardData : item;
        })
      )
    );
    toast.success(message);
  };

  const updateInsightView = async () => {
    const product = isAllselected(
      localSelectedProduct.current,
      allProductOptions
    );
    const payload = {
      kpiId: selectedKPI?.KpiId,
      roleId,
      cardId: data.cardId,
      title: updatedKpiName,
      cardView: selectedCardView,
      custInsightsBrdId: data.custInsightsBrdId,
      filter: {
        selectedAccount: selectedCustomer,
        selectedEcosystem,
        selectedProduct: product,
        selectedTimePeriod,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad.current,
      },
      kpiFilters: selectedLocalfilter(data.localFilters, localFiltersData),
      expression: expressionToSave,
      appId: selectedAppId,
      positionIndex: data.positionIndex,
      timeSpan: timespan,

      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,

      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',

      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,

      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,

      advanceFilterValue: getAdvanceFilterValue(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),
      // Add Local filters in card to get local filters in edit mode if board is not saved yet.
      localFilters: localFilters,
    };
    const cardData = {
      ...payload,
      title: updatedKpiName,
      filter: payload.filter,
      chartData: chartData,
      selectedAppLink: selectedAppLink,
    };

    dispatch(
      setCardsInsight(
        cards.map((item) => {
          return item.positionIndex === payload.positionIndex &&
            item.isDeleted !== true
            ? cardData
            : item;
        })
      )
    );
    toast.success(CHART_UPDATE_MESSAGE);

    dispatch(setCustomerViewButtonDisabled(false));
  };

  const getKpiInfoToSave = (selectedKPI) => {
    let finalSelectedKpi = selectedKPI;
    const dimension = dimensionOptions.filter(
      (oneDimension) =>
        oneDimension?.dimensionName === selectedDimension?.dimensionName
    );
    return { ...finalSelectedKpi, dimensions: dimension };
  };

  const addToHome = async () => {
    if (page === CONFIGURE_VIEW.insights) {
      addToinsights();
      return;
    }
    if (cards?.length >= 20) {
      toast.error(CHART_LIMIT_ERROR);
      return;
    }
    const positionIndex = cards?.length + 1;
    const account = isAllselected(selectedCustomer, allCustomers);
    const payload = {
      operation: 'POST',
      unixId,
      roleId,
      positionIndex: positionIndex,
      kpiId: selectedKPI?.KpiId,
      title: updatedKpiName,
      cardView: selectedCardView,
      cardId: '',
      filter: {
        selectedAccount: account,
        selectedEcosystem: focusCheck
          ? []
          : selectedEcosystem?.length === ecosystemOptions.length
          ? []
          : selectedEcosystem,
        selectedProduct: localSelectedProduct.current,
        selectedTimePeriod,
        focusAccountChecked: focusCheck,
        kpiName: selectedKPI?.KpiName,
        selectedKPI: getKpiInfoToSave(selectedKPI),
        noDimensionValue,
        chartType,
        accountableProduct: accountableProductsChecked,
        selectedSquad: localSelectedSquad.current,
      },
      kpiFilters: selectedLocalfilter(localFilters, localFiltersData),
      expression: expressionToSave,
      appId: selectedAppId,
      timeSpan: timespan,
      advanceId: advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        ? advanceComparePropsRef.current?.selectDropdownOption?.advanceId
        : null,

      advanceEnabled:
        advanceComparePropsRef.current?.selectDropdownOption &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? 'Y'
          : 'N',

      advanceType:
        advanceComparePropsRef.current?.compareBy &&
        advanceComparePropsRef.current?.selectDropdownOption !== '' &&
        advanceComparePropsRef.current?.selectDropdownOption?.length !== 0
          ? advanceComparePropsRef.current?.compareBy
          : null,

      adavanceTimeSpanExpression: advanceComparePropsRef.current
        ?.selectDropdownOption?.adavanceTimeSpanExpression
        ? advanceComparePropsRef.current?.selectDropdownOption
            ?.adavanceTimeSpanExpression
        : null,

      advanceFilterValue: getAdvanceFilterValue(
        advanceComparePropsRef,
        vReplaceStringForDynamicFilter
      ),
      // Add Local filters in card to get local filters in edit mode if board is not saved yet.
      localFilters: localFilters,
    };

    const { message = '', cardId } = await addToMyView(payload);
    const cardData = {
      ...payload,
      filter: payload.filter,
      cardId: cardId,
      title: updatedKpiName,
      chartData: chartData,
      selectedAppLink: selectedAppLink,
      expression: expression,
    };

    dispatch(setCards([...cards, cardData]));

    toast.success(message);
  };

  const checkSingleSelectEligibility = () => {
    let callApi = true;
    setFilterValidation(callApi);
    if (
      selectedDimension?.filterSingleSelect?.accountSingleSelect === 'Y' &&
      localAccount.current.length !== 1
    ) {
      callApi = false;
      if (page !== CONFIGURE_VIEW.insights) {
        dispatch(setSelectedCustomer([]));
        localAccount.current = [];
      }
    }
    if (
      selectedDimension?.filterSingleSelect?.squadSingleSelect === 'Y' &&
      localSelectedSquad.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedSquad([]));
      localSelectedSquad.current = [];
    }
    if (
      selectedDimension?.filterSingleSelect?.ecosystemSingleSelect === 'Y' &&
      selectedDimension?.filterEnable?.ecosystemEnable === 'Y' &&
      !priorityRef?.current &&
      localEcosystem.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedEcosystem([]));
      localEcosystem.current = [];
    }
    if (
      selectedDimension?.filterSingleSelect?.productSingleSelect === 'Y' &&
      localSelectedProduct.current.length !== 1
    ) {
      callApi = false;
      dispatch(setSelectedProduct([]));
      localSelectedProduct.current = [];
    }
    setFilterValidation(callApi);
    return callApi;
  };

  //onselection of advance dropdown value
  useEffect(() => {
    if (editFirstLoad && view === 'edit') {
      advanceComparePropsRef.current = selectedAdvanceOptions;
      if (
        selectedAdvanceOptions &&
        selectedAdvanceOptions?.selectDropdownOption
      ) {
        buildExpression();
      }
    } else if (view !== 'edit') {
      advanceComparePropsRef.current = selectedAdvanceOptions;
      if (
        selectedAdvanceOptions &&
        selectedAdvanceOptions?.selectDropdownOption
      ) {
        buildExpression();
      }
    }
  }, [selectedAdvanceOptions?.selectDropdownOption]);

  // onchange of advance compareType load default view
  useEffect(() => {
    if (selectedAdvanceOptions?.selectDropdownOption === '') {
      buildExpression();
    }
  }, [selectedAdvanceOptions?.compareBy]);

  const generateMultiDimensionObject = async (
    advanceCompareProps,
    firstExp,
    oldChartType,
    editAppId,
    editFlowSelectedTimePeriod
  ) => {
    dispatch(setKPIclickedStatus(true));
    dispatch(setNoDimensionValue(null));

    //advanceCompareProps will be undefined only for reset
    if (advanceCompareProps === undefined) {
      advanceComparePropsRef.current = '';
      dispatch(setAdvanceOptions(advanceCompareProps));
    }

    let dimensionObjOne = {
      dimensionType: 'Cateogry',
      dimensionName: selectedDimension?.dimensionName,
    };
    let dimensionObjTwo;
    if (
      advanceCompareProps &&
      advanceCompareProps?.compareBy === DIMENSION &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName &&
      advanceCompareProps?.selectDropdownOption?.PossibleDimensionName !==
        'undefined'
    ) {
      dimensionObjTwo = {
        dimensionType: 'Advance',
        dimensionName:
          advanceCompareProps?.selectDropdownOption?.PossibleDimensionName,
      };
    }
    dispatch(setChartData([]));
    dispatch(
      setChartType(
        advanceCompareProps?.selectDropdownOption?.chartType
          ? advanceCompareProps?.selectDropdownOption?.chartType
          : oldChartType
          ? oldChartType
          : chartType
      )
    );
    let dimension = [];

    dimension.push(dimensionObjOne);
    if (dimensionObjTwo) {
      dimension.push(dimensionObjTwo);
    }
    let measure = [];
    let measureObjOne = {
      timesPeriod:
        advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
        advanceCompareProps?.selectDropdownOption !== ''
          ? BENCHMARK_LEGEND_FIRST
          : advanceCompareProps?.compareBy === COMPARE_KPI &&
            advanceCompareProps?.selectDropdownOption !== ''
          ? selectedKPI?.KpiName
          : editFlowSelectedTimePeriod?.label
          ? editFlowSelectedTimePeriod?.label
          : selectedTimePeriod?.label,
      index: 1,
      expression: firstExp || expression,
    };
    measure.push(measureObjOne);
    let measureObjTwo;
    if (
      advanceCompareProps?.compareBy === COMPARE_TIME_PERIOD &&
      advanceCompareProps?.selectDropdownOption?.possibleTimePeriod
    ) {
      measureObjTwo = {
        timesPeriod:
          advanceCompareProps?.selectDropdownOption?.possibleTimePeriod,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression?.current : 'test'
              )
            : replaceFilterInExpression(advanceCompareProps, filterExpression),
      };

      measure.push(measureObjTwo);

      //SETTING UP THE dimension NAME TO MONTH_N IF PRIMARY dimension IS MONTH AND COMPARE BY IS TIME PERIOD.

      if (selectedDimension?.dimensionName === 'Month') {
        let dimensionObjOne = {
          dimensionType: 'Cateogry',
          dimensionName: 'Month_N',
        };

        dimension = [dimensionObjOne];
      }
    }

    if (
      advanceCompareProps?.compareBy === COMPARE_BENCHMARK &&
      !isEmpty(advanceCompareProps?.selectDropdownOption)
    ) {
      measureObjTwo = {
        timesPeriod: advanceCompareProps?.selectDropdownOption?.label,
        index: 2,
        expression:
          view === 'add'
            ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
                EXPRESSION_PLACEHOLDER_TEXT,
                filterExpression.current ? filterExpression?.current : 'test'
              )
            : replaceFilterInExpression(advanceCompareProps, filterExpression),
      };
      measure.push(measureObjTwo);
      //SETTING UP THE dimension NAME TO MONTH_N IF PRIMARY dimension IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension?.dimensionName === 'Month') {
        let dimensionObjOne = {
          dimensionType: 'Cateogry',
          dimensionName: 'Month_N',
        };

        dimension = [dimensionObjOne];
      }
    }
    if (
      advanceCompareProps?.compareBy === COMPARE_KPI &&
      !isEmpty(advanceCompareProps?.selectDropdownOption)
    ) {
      dispatch(setSelectedCardView('table'));
      let exp = advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp
        : advanceCompareProps?.selectDropdownOption;

      Array.isArray(exp)
        ? exp?.map((oneKpi) => {
            measureObjTwo = {
              timesPeriod: oneKpi?.label || oneKpi?.timesPeriod,
              index: measure.length + 1,
              expression:
                view === 'add'
                  ? oneKpi?.advTimeperiodExp?.replaceAll(
                      EXPRESSION_PLACEHOLDER_TEXT,
                      filterExpression.current
                        ? filterExpression?.current
                        : 'test'
                    )
                  : replaceFilterInExpression(oneKpi, filterExpression),
            };
            measure.push(measureObjTwo);
            return oneKpi;
          })
        : measure.push({
            timesPeriod: exp?.value,
            index: measure.length,
            expression: exp?.advTimeperiodExp
              ?.replaceAll(
                'vReplaceString',
                filterExpression ? filterExpression : 'test'
              )
              ?.replace(/(^"|"$)/g, ''),
          });
      //SETTING UP THE dimension NAME TO MONTH_N IF PRIMARY dimension IS MONTH AND COMPARE BY IS TIME PERIOD.
      if (selectedDimension.dimensionName === 'Month') {
        let dimensionObjOne = {
          dimensionType: 'Cateogry',
          dimensionName: 'Month_N',
        };

        dimension = [dimensionObjOne];
      }
    }
    // trackPromise(
    let limit =
      advanceCompareProps?.selectDropdownOption?.rowsLimit?.toString();

    trackPromise(
      getMultiDimensionChartData(
        measure,
        dimension,
        editAppId !== undefined ? editAppId : selectedAppId,
        advanceCompareProps?.selectDropdownOption?.chartType
          ? advanceCompareProps?.selectDropdownOption?.chartType
          : oldChartType
          ? oldChartType
          : chartType,
        limit
      )
        .then((res) => {
          if (!res.data?.values[0]) {
            if (
              advanceCompareProps?.selectDropdownOption?.chartType ===
                COMPARE_BENCHMARK_NO_DIMENSION_CHARTTYPE ||
              res?.data[0]?.values[0]?.name === BENCHMARK_LEGEND_FIRST
            ) {
              dispatch(setNoDimensionValue(res?.data[0]?.values[0]?.data[0]));
              dispatch(
                setBenchmarkNoDimensionValue(res?.data[0]?.values[1]?.data[0])
              );
            } else {
              dispatch(
                setNoDimensionValue(
                  res?.data[0]?.category ? res?.data[0]?.category[0] : null
                )
              );
              dispatch(setBenchmarkNoDimensionValue(''));
            }
          }
          dispatch(setChartData(res.data[0] ? res.data[0] : []));
          dispatch(setKPIclickedStatus(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setKPIclickedStatus(false));
        })
    );
  };

  const replaceFilterInExpression = (advanceCompareProps, filterExpression) => {
    let multiExpression = advanceCompareProps?.expression
      ? advanceCompareProps?.expression
      : advanceCompareProps?.advTimeperiodExp;
    let finalExpression = advanceCompareProps?.selectDropdownOption
      ? advanceCompareProps?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        )
      : multiExpression.replaceAll(
          FILTER_PLACE_HOLDER,
          filterExpression.current ? filterExpression.current : 'test'
        );

    finalExpression = finalExpression?.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      filterExpression.current ? filterExpression?.current : 'test'
    );
    return finalExpression;
  };

  return (
    <Container className='p-0 gne-container'>
      {/* Header */}
      <Row className='justify-content-between align-items-center'>
        <Col
          sm={8}
          className='p-0'>
          {children}
        </Col>
        <Col
          sm={4}
          className='d-flex justify-content-end align-items-center pe-0 me-0'>
          <span className='text text__bold badge-circle'>5</span>
          <Button
            variant={`outline-primary btn-gne btn-gne__outline ${
              view === 'add' ? 'btn-gne__outline--add' : ''
            } add-button`}
            onClick={view === 'edit' ? updateView : addToHome}
            className={''}
            disabled={promiseInProgress || !enableApplyDisableAddtoButton}>
            {buttonText}
          </Button>
        </Col>
      </Row>
      {/* Main filter */}
      <KpiMapping
        page={page}
        className='mt-2'
      />
      {/* Dynamic Filter & table/chart */}
      <Row className='pb-2'>
        <Col
          sm={3}
          style={{
            backgroundColor: 'var(--color-light-brown)',
          }}>
          <KPIFilters
            page={page}
            view={view}
            classes={classes}
            isApplyDisabled={applyButton}
            promiseInProgress={promiseInProgress}
            buildExpression={buildExpression}
            accountableProductsChecked={accountableProductsChecked}
            handleProductType={handleProductType}
          />
        </Col>
        <Col
          sm={9}
          className='pe-0'>
          <Row>
            <Col sm={12}>
              <TileName
                data={data}
                defaultKPIName={updatedKpiName}
                handleTileChange={(value) => {
                  setUpdatedKpiName(value);
                }}
              />
            </Col>
          </Row>
          <Row className=''>
            <Col
              sm={12}
              className='pe-0 ps-1'>
              {
                <Fade>
                  <Row className='mt-3 '>
                    <ChartAndTableView
                      page='My View'
                      timespan={timespan}
                      onResetClicked={() => {
                        generateMultiDimensionObject(
                          undefined,
                          undefined,
                          oldChartType
                        );
                      }}
                      filterValidation={filterValidation}
                      enableApplyDisableAddtoButton={
                        enableApplyDisableAddtoButton
                      }
                    />
                  </Row>
                </Fade>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
