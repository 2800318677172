import axiosQlikClient from 'api';
import axiosDBClient from 'axiosDBClient';
import { trackPromise } from 'react-promise-tracker';
import {
  setEcosystemOptions,
  setProductOptions,
  setTimePeriodOptions,
  setTimePeriods,
  setChartData,
  setNoDimensionValue,
  setSelectedProduct,
  setCards,
  setImpactedCards,
  setSelectedTimePeriod,
  setTimespan,
  setSquadOptions,
  setDefaultCard,
  setSelectedSquad,
} from './homeMyViewSlice';
import { setQlikToken } from 'store/auth/Authslice';
import isArray from 'lodash/isArray';
import { getQlikSenseData, setQlikAuth } from 'services/qlik.service';
import { areas } from 'constants/constants';
import { buildCardDetails } from 'utils/utils';
import { getMultiDimensionChartData } from 'services/trends.service';
import { getCustomerData } from 'store/customer/customer-view-actions';

const getEcosystems = async (unixId, roleId) => {
  const response = await axiosDBClient.get('filter/ecosystem', {
    params: { unixId, roleId },
  });

  const ecosystems = response.data.result;
  let ecosystemOptions = [];

  if (ecosystems?.length > 0) {
    ecosystemOptions = ecosystems.map((ecosystem) => {
      return {
        label: ecosystem.ecosystemName,
        value: ecosystem.ecosystemId,
      };
    });
  }
  return ecosystemOptions;
};
/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getEcosystemData = (unixId, roleId) => {
  return async (dispatch) => {
    try {
      const ecoSystem = await getEcosystems(unixId, roleId);
      if (ecoSystem?.length > 0) {
        dispatch(setEcosystemOptions(ecoSystem));
      }
    } catch (error) {}
  };
};

/**
 * Get product data from the database
 * that will be displayed in the product dropdown
 * store the product in the store
 * @returns
 */
export const getProductData = (selectedProduct) => {
  return async (dispatch) => {
    const getProducts = async () => {
      const response = await axiosDBClient.get('filter/products');

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }

      return response.data.result;
    };

    try {
      const products = await getProducts();

      dispatch(setProductOptions(products));
      if (isArray(selectedProduct)) {
        dispatch(setSelectedProduct(selectedProduct));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
/**
 * Get squad data from the database
 * that will be displayed in the squad dropdown
 * store the squad in the store
 * @returns
 */
export const getSquadData = (selectedSquad) => {
  return async (dispatch) => {
    const getSquads = async () => {
      const url = `filter/squads`;
      const response = await axiosDBClient.get(url);
      if (response.data.error) {
        throw new Error('Could not fetch squads!');
      }

      return response.data.result;
    };

    try {
      const squads = await getSquads();
      dispatch(setSquadOptions(squads));
      if (isArray(selectedSquad)) {
        dispatch(setSelectedSquad(selectedSquad));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * Gets time period data and formats it so
 * the Time Period dropdown can use it
 * also stores time period
 * store the kpi time period in the store
 * @param {*} kpiId the kpi id
 * @returns
 */
export const getTimePeriodData = (kpiId) => {
  return async (dispatch) => {
    const getTimePeriods = async () => {
      const response = await axiosDBClient.get('filter/time-period', {
        params: { kpiId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch products!');
      }
      return response.data.result;
    };

    try {
      const timePeriods = await getTimePeriods();
      dispatch(setTimePeriods(timePeriods));
      const timePeriodOptions = timePeriods.map((timePeriod) => {
        return {
          label: timePeriod.timePeriodName,
          value: timePeriod.timePeriodId,
        };
      });

      dispatch(setTimePeriodOptions(timePeriodOptions));
      let exp = timePeriods.filter((tp) => tp.uXDefaultValue === 'Y')[0];
      dispatch(
        setSelectedTimePeriod({
          label: exp.timePeriodName,
          value: exp.timePeriodId,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

/**
 * Make an API call to get qlik data
 * @param {*} token
 * @param {*} expression the expression used qMeasure
 * @param {*} dimension the dimension used for qDimension
 * @returns
 */
export const getQlikFilteredData = (
  token,
  cookie,
  expression,
  dimension,
  appId
) => {
  return async (dispatch) => {
    const getQlikData = async () => {
      const limit = 30;
      const response = await axiosQlikClient.get('qlik', {
        params: { measure: expression, dimension, appId, limit },
        headers: { qlikCookie: cookie, Authorization: `Bearer ${token}` },
      });

      if (response.error) {
        throw new Error('Could not fetch qlik data!');
      }

      return response;
    };

    try {
      trackPromise(
        getQlikData().then((response) => {
          setQlikAuth(
            response?.headers['authorization']?.split(' ')[1],
            response?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: response?.headers['authorization']?.split(' ')[1],
              qlikCookie: response?.headers['qlikcookie'],
            })
          );

          if (!response.data?.data?.values[0]) {
            dispatch(setNoDimensionValue(response.data.data.category[0]));
          }
          dispatch(setChartData(response.data.data));
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const addToMyView = async (payload) => {
  const setAddToMyView = async () => {
    const response = await axiosDBClient.post('v2/views/configure', {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not add data!');
    }

    return response.data;
  };

  try {
    const data = await setAddToMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateMyView = async (payload) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.put(
      `v2/views/configure/${payload.cardId}`,
      {
        ...payload,
      }
    );

    if (response.data.error) {
      throw new Error('Could not update data!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteMyView = async (cardId, positionIndex, unixId) => {
  const setDeleteMyView = async () => {
    const response = await axiosDBClient.delete(
      `v2/views/configure/${cardId}`,
      {
        data: {
          positionIndex: positionIndex,
          unixId: unixId,
        },
      }
    );

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  };

  try {
    const data = await setDeleteMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
export const updateCardTitle = async (cardId, title, unixId) => {
  const setUpdateMyView = async () => {
    const response = await axiosDBClient.patch(
      `v2/views/configure/title/${cardId}`,
      {
        title: title,
        unixId: unixId,
      }
    );

    if (response.data.error) {
      throw new Error('Could not delete data!');
    }

    return response.data;
  };

  try {
    const data = await setUpdateMyView();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getQlikDataForCard = async (
  expression,
  dimension,
  appId,
  limit
) => {
  try {
    return await getQlikSenseData(expression, dimension, appId, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const getDefultViewCard = (
  unixId,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad,
  roleId,
  defaultCustomerType
) => {
  return async (dispatch) => {
    const getDefulatViewCards = async () => {
      const response = await axiosDBClient.get(
        `v2/myview/configuredefault?roleId=${roleId}&unixId=${unixId}`
      );

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data.result;
    };

    try {
      const priorityCustomer = await getCustomerData(
        true,
        '',
        0,
        roleId,
        unixId,
        '',
        '',
        defaultCustomerType,
        '',
        '',
        '',
        true
      );
      const defaultCards = await getDefulatViewCards();
      for (let card of defaultCards) {
        card = await buildCardDetails(
          card,
          false,
          userRestrictedRole,
          userRestrictedEcosystemt,
          restrictedProductSquad,
          priorityCustomer?.result?.data
        );
      }
      if (defaultCards?.length === 0) {
        dispatch(setDefaultCard([]));
        return;
      } else {
        dispatch(setDefaultCard(defaultCards));
      }
      let cardData = [...defaultCards];

      defaultCards.map(async (val, i) => {
        trackPromise(
          getMultiDimensionChartData(
            val?.advance?.measure,
            val?.advance?.dimension,
            val?.appId,
            val?.advance?.chartType
          ).then((res) => {
            cardData[defaultCards.indexOf(val)] = {
              ...val,
              chartData: res?.data[0],
              chartType: val?.advance?.chartType,
            };
            dispatch(setDefaultCard([...cardData]));
          }),
          areas.defaultView
        );
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const getImpactedCardsData = (unixId, roleId) => {
  return async (dispatch) => {
    const getImpactedCards = async () => {
      const response = await axiosDBClient.get(`impacted-cards`, {
        params: { unixId, roleId },
      });

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data;
    };

    try {
      const cards = await getImpactedCards();

      if (cards?.length === 0) {
        dispatch(setImpactedCards([]));
        return;
      } else {
        dispatch(setImpactedCards(cards));
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};
export const discardImpactedCardsData = (unixId, roleId) => {
  return async (dispatch) => {
    const discardImpactedCards = async () => {
      const response = await axiosDBClient.put(`impacted-cards`, {
        unixId,
        roleId,
      });

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data.data;
    };

    try {
      const cards = await discardImpactedCards();

      if (cards?.length === 0) {
        dispatch(setImpactedCards([]));
        return;
      } else {
        dispatch(setImpactedCards(cards));
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};
export const deleteImpactedCardWarning = async (id) => {
  const setDeleteWarning = async () => {
    const response = await axiosDBClient.patch(`impacted-cards/${id}`);

    if (response.data.error) {
      throw new Error('Could not delete warning!');
    }

    return response.data;
  };

  try {
    const data = await setDeleteWarning();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getCardsData = (
  unixId,
  roleId,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad,
  offset,
  limit,
  defaultCustomerType
) => {
  return async (dispatch) => {
    const getCards = async () => {
      const response = await axiosDBClient.get(`v2/views/configure`, {
        params: { unixId, roleId, offset, limit },
      });

      if (response.data.error) {
        throw new Error('Could not fetch my view data!');
      }

      return response.data.data;
    };

    try {
      const priorityCustomer = await getCustomerData(
        true,
        '',
        0,
        roleId,
        unixId,
        '',
        '',
        defaultCustomerType,
        '',
        '',
        '',
        true
      );
      const cards = await getCards();
      for (let card of cards) {
        try {
          card = await buildCardDetails(
            card,
            false,
            userRestrictedRole,
            userRestrictedEcosystemt,
            restrictedProductSquad,
            priorityCustomer?.result?.data
          );
        } catch (error) {
          console.log(error);
        }
      }
      if (cards?.length === 0) {
        dispatch(setCards([]));
        return;
      }
      let cardData = [...cards];
      cards.map(async (val, i) => {
        trackPromise(
          getMultiDimensionChartData(
            val?.advance?.measure,
            val?.advance?.dimension,
            val?.appId,
            val?.advance?.chartType
          ).then((res) => {
            cardData[cards.indexOf(val)] = {
              ...val,
              chartData: res?.data[0],
              chartType: val?.advance?.chartType,
            };
            dispatch(setCards([...cardData]));
          }),
          areas.home
        );
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  };
};

export const setQlikCardData = (cards) => {
  return async (dispatch) => {
    let chartDataArr = [...cards];

    for (const card of cards) {
      const { filter, expression, appId } = card;
      const { dimensionName = '', timespanExpression } = filter;
      const expressionWithoutQuotes = expression?.replace(/(^"|"$)/g, '');
      const limit = 30;

      trackPromise(
        getQlikDataForCard(
          expressionWithoutQuotes,
          dimensionName,
          appId,
          limit
          // eslint-disable-next-line no-loop-func
        ).then((data) => {
          if (data) {
            try {
              // get the timespan based on the timespan expression
              getQlikSenseData(timespanExpression, null, appId, null).then(
                (response) => {
                  const savedTimespan = response.data.data.category[0].replace(
                    new RegExp("'", 'g'),
                    ' '
                  );
                  chartDataArr[cards.indexOf(card)] = {
                    ...card,
                    chartData: data?.data?.data,
                    timespan: savedTimespan,
                  };

                  dispatch(setCards([...chartDataArr]));
                }
              );
            } catch (error) {
              console.log('ERROR', error);
            }

            setQlikAuth(
              data?.headers['authorization']?.split(' ')[1],
              data?.headers['qlikcookie']
            );
            dispatch(
              setQlikToken({
                qlikToken: data?.headers['authorization']?.split(' ')[1],
                qlikCookie: data?.headers['qlikcookie'],
              })
            );
          }
        }),
        areas.home
      );
    }
  };
};
export const updateCardPositions = async (payload) => {
  const updatePositions = async () => {
    const response = await axiosDBClient.patch(`v2/views/configure/`, {
      ...payload,
    });

    if (response.data.error) {
      throw new Error('Could not update positions!');
    }

    return response.data;
  };

  try {
    const data = await updatePositions();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const getTimespan = (expression, appId) => {
  return async (dispatch) => {
    try {
      trackPromise(
        getQlikSenseData(expression, null, appId, null).then((data) => {
          dispatch(
            setTimespan(
              data.data.data.category[0].replace(new RegExp("'", 'g'), ' ')
            )
          );

          setQlikAuth(
            data?.headers['authorization']?.split(' ')[1],
            data?.headers['qlikcookie']
          );

          dispatch(
            setQlikToken({
              qlikToken: data?.headers['authorization']?.split(' ')[1],
              qlikCookie: data?.headers['qlikcookie'],
            })
          );
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const getReleaseData = async (roleId, unixId) => {
  try {
    const response = await axiosDBClient.get(`release-notes`, {
      params: { roleId, unixId },
    });
    if (response.error) {
      throw new Error('NO release Data');
    }
    return response?.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const logoutSSO = async (id) => {
  if (id !== undefined && id !== '') {
    try {
      const payload = { id };
      return await axiosDBClient.post('logout', {
        ...payload,
      });
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  }
};

export const updateReleaseData = async (roleId, unixId) => {
  try {
    const body = { roleId: roleId, unixId: unixId };
    const response = await axiosDBClient.put(`release-notes`, {
      ...body,
    });
    if (response.error) {
      throw new Error('NO release Data');
    }
    return response.data.result;
  } catch (error) {
    console.log(error.message);
  }
};
export const getSystemAlertsData = async (roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/notifications/system-alert?roleId=${roleId}`
    );
    return response?.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const getNotificationsData = async (unixId, roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/notifications/user?unixId=${unixId}&roleId=${roleId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const downloadTileData = async (kpiTtitle, payload) => {
  try {
    const res = await axiosQlikClient.post(`/export`, payload, {
      headers: {
        qlikCookie: localStorage.getItem('qlikCookie'),
        Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
      },
      responseType: 'blob',
    });
    const FileDownload = require('js-file-download');
    const response = FileDownload(res?.data, `${kpiTtitle}-Data.xlsx`);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('Could not download data!');
  }
};

export const getFavoriteData = async (unixId, roleId) => {
  try {
    const response = await axiosDBClient.get(
      `/report/getReports?unixId=${unixId}&roleId=${roleId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
