import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'utils/hooks/useClickAway';

import {
  CustomDropdownIndicator,
  CustomMenuList,
  CustomMenuPortal,
  CustomValueContainer,
  handleInputChange,
} from 'utils/utils';

const ReactSelectSearch = (WrappedComponent) => {
  const WithSearch = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const selectRefProp = useRef();

    const containerRef = useRef();
    useClickAway(containerRef, () => {
      setInputValue('');
      setIsFocused(false);
    });

    const mapOptionsForMenu = useCallback(
      (options) => {
        try {
          const selectedValue = selectRefProp.current?.props?.value;
          if (!selectedValue) {
            return options;
          }

          if (Array.isArray(selectedValue) && inputValue === '') {
            if (selectedValue?.length === 0) {
              return options;
            }

            const valueSet = new Set(
              selectedValue.map((option) => option.value)
            );

            return [
              ...selectedValue,
              ...options.filter((option) => !valueSet.has(option.value)),
            ];
          }

          return [...options];
        } catch (error) {
          console.log(error);
          return options;
        }
      },
      [isFocused, inputValue]
    );

    return (
      <div
        ref={containerRef}
        className={props.containerCss}>
        <WrappedComponent
          {...props}
          onMenuOpen={() => {
            setTimeout(() => {
              if (
                !document.activeElement.contains(
                  document.querySelector('.search-box')
                )
              ) {
                selectRefProp.current.blur();
              }
            });
            setInputValue('');
            props?.onMenuOpen && props?.onMenuOpen();
          }}
          onMenuClose={() => {
            setIsFocused(false);
            props?.onMenuClose && props?.onMenuClose();
          }}
          mapOptionsForMenu={props.isMulti ? mapOptionsForMenu : undefined}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            MenuPortal: CustomMenuPortal,
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer,
            ...props?.components,
          }}
          inputValue={inputValue}
          isSearchable={false}
          onMenuInputFocus={() => {
            setIsFocused(true);
          }}
          menuPosition={props.menuPosition}
          onChange={(value) => {
            props.onChange(value);
            if (!props.isMulti) {
              setInputValue('');
              setIsFocused(false);
            }
          }}
          selectRef={selectRefProp}
          onInputChange={(value, action) => {
            props?.onDropdownAction && props?.onDropdownAction(action?.action);
            setInputValue(handleInputChange(value, action));
          }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
        />
      </div>
    );
  };

  return WithSearch;
};
export default ReactSelectSearch;
