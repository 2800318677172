import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'components/shared/loader/Loader';
import { chartOptions } from './chartOptions';

const Chart = ({
  chartData,
  kpiName,
  chartType,
  radius,
  style,
  legend,
  isMultiChart,
  hashView,
  chartAllData,
  screenType,
  numberOfBar,
  isDefaultScroll,
}) => {
  const { promiseInProgress } = usePromiseTracker();

  const type = chartType || 'bar';
  const cssStyle = style || { height: '100%', width: '100%' };
  const noData = chartData?.values?.length === 0;

  const contractDiscount = chartAllData?.discount;
  const contractRebate = chartAllData?.rebate;

  return (
    <>
      {promiseInProgress && !isMultiChart && <Loader component='chart' />}

      {!promiseInProgress && !isMultiChart && !noData && (
        <ReactEcharts
          option={chartOptions(
            type,
            chartData,
            kpiName,
            radius,
            legend,
            hashView,
            contractDiscount,
            contractRebate,
            screenType,
            numberOfBar,
            isDefaultScroll
          )}
          style={cssStyle}
          render='svg'
        />
      )}

      {isMultiChart && (
        <ReactEcharts
          option={chartOptions(
            type,
            chartData,
            kpiName,
            radius,
            legend,
            hashView,
            contractDiscount,
            contractRebate,
            screenType,
            numberOfBar,
            isDefaultScroll
          )}
          style={cssStyle}
          render='svg'
        />
      )}
    </>
  );
};

export default Chart;
