/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade } from 'react-awesome-reveal';

import TileContainer from 'components/shared/tiles/TileContainer';
import ConfigureMyView from '../configure-my-view/ConfigureMyView';

import Documents from 'components/documents/Documents';
import { CONFIGURE_VIEW } from 'constants/constants';

import classes from './Favorites.module.scss';
import TileImpactWarning from '../TileImpactWarning';
import WarningOnHomeMyView from '../WarningOnHomeMyView';
const Favorites = () => {
  const [key, setKey] = useState('myView');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showGlobalwarning, setShowGlobalwarning] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Tabs
      id='homeView'
      activeKey={key}
      onSelect={(k) => setKey(k)}
      unmountOnExit={true}
      mountOnEnter={true}
      className={`mb-3 ${classes.stickyTab}`}>
      <Tab
        eventKey='myView'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myView' ? 'my-view-active' : 'my-view'
              }`}
            />{' '}
            My View
          </span>
        }>
        {showGlobalwarning && (
          <Fade>
            <TileImpactWarning
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
              setShowGlobalwarning={setShowGlobalwarning}
            />
          </Fade>
        )}
        <Fade>
          <ConfigureMyView page={CONFIGURE_VIEW.myView} />
        </Fade>
        <DndProvider backend={HTML5Backend}>
          <TileContainer
            page={CONFIGURE_VIEW.myView}
            setShowGlobalwarning={setShowGlobalwarning}
          />
        </DndProvider>
        <WarningOnHomeMyView
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          setShowGlobalwarning={setShowGlobalwarning}
        />
      </Tab>

      <Tab
        eventKey='myDefault'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myDefault' ? 'my-default-active' : 'my-default'
              }`}
            />{' '}
            My Default
          </span>
        }>
        {key === 'myDefault' && (
          <DndProvider backend={HTML5Backend}>
            <TileContainer
              page={CONFIGURE_VIEW.myDefault}
              activeTab={key}
              setShowGlobalwarning={setShowGlobalwarning}
            />
          </DndProvider>
        )}
      </Tab>

      <Tab
        eventKey='myDocs'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myDocs'
                  ? 'my-view-documents-active'
                  : 'my-view-documents'
              }`}
            />{' '}
            Documents
          </span>
        }>
        <Documents />
      </Tab>
    </Tabs>
  );
};

export default Favorites;
