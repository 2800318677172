import Checkbox from '@mui/material/Checkbox';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import {
  CONFIGURE_VIEW,
  CUSTOMER_HELP_TEXT,
  CUSTOMER_TOOLTIP_TEXT,
  CUSTOMER_TOOLTIP_TEXT_PART2,
  CUSTOMER_TYPE_FLAG,
} from 'constants/constants';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { wrapMenuList } from 'react-select-async-paginate';
import { getCustomerData } from 'store/customer/customer-view-actions';
import {
  setAdvanceSearchClicked,
  setAdvanceSearchSelectedData,
  setAllCustomers,
  setFocusAccountChecked,
  setFocusAccountDisabled,
  setPrevFocusAccountChecked,
  setPriorityCustomers,
} from 'store/customer/customerViewSlice';
import {
  ValueContainer,
  dropdownOptions,
  formatCustomerFilterLabel,
  getArrayValues,
} from 'utils/utils';
import classes from './customerDropdown.module.scss';
import Dropdownpill from './Dropdownpill';

const CustomerDropdown = ({
  page,
  isMulti,
  isDisabled,
  isClearable,
  onChangeHandler,
  currentInsightflag,
  clearDependencies,
  focusDisabled,
  focusChecked,
}) => {
  const dispatch = useDispatch();
  const firstLoading = useRef(true);
  const shouldLoadMore = useRef(false);
  const [offset, setOffset] = useState(100);
  const [isExactSearch, setIsExactSearch] = useState(false);

  const {
    isApply,
    advanceSearchClicked,
    selectedCustomer,
    advanceSearchSelectedData,
    focusAccountChecked,
    focusAccountDisabled,
    priorityCustomers,
    defaultCustomerType,
  } = useSelector((state) => state?.CustomerView);

  const selectedDimension = useSelector(
    (state) => state?.trends?.selectedDimension
  );
  const selectedKPI = useSelector((state) => state?.trends?.selectedKPI);
  const ecosystem = useSelector((state) => state.trends.KPIFilter.Ecosystem);
  const customerTypes = useSelector((state) => state.trends.customerTypes);

  const selectedEcosystemLabels = getArrayValues(ecosystem?.value).split(',');
  const { unixId, roleId } = useSelector((state) => state.Auth.userClaimObject);

  const categoriesToDisplay = Object.keys(advanceSearchSelectedData).filter(
    (category) =>
      advanceSearchSelectedData[category] &&
      advanceSearchSelectedData[category].length > 0
  );

  const handleAdvanceSearch = () => {
    dispatch(
      setAdvanceSearchClicked({ id: page, value: !advanceSearchClicked[page] })
    );
  };

  const searchExacthandle = () => {
    setIsExactSearch(!isExactSearch);
  };

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    if (firstLoading.current) {
      firstLoading.current = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current = loadMore;

    return loadMore;
  };

  const getEcosystem = () => {
    if (
      (page === CONFIGURE_VIEW.trends &&
        ecosystem?.value?.length > 0 &&
        !focusAccountChecked) ||
      selectedDimension?.filterEnable?.ecosystemEnable === 'N'
    ) {
      return selectedEcosystemLabels;
    }
    return [];
  };

  const getFlag = (TypeArray) => {
    if (page === CONFIGURE_VIEW.copyInsight) {
      return currentInsightflag;
    }

    if (!TypeArray) {
      return page === CONFIGURE_VIEW.trends
        ? customerTypes //KPI level
        : defaultCustomerType;
    }

    const uniqueFlags = [
      ...new Set(TypeArray.map((i) => CUSTOMER_TYPE_FLAG.get(i.label))),
    ].flat();
    return uniqueFlags;
  };

  const userEcosystem = [
    CONFIGURE_VIEW.trends,
    CONFIGURE_VIEW.configurePriority,
    // CONFIGURE_VIEW.viewList,
    // CONFIGURE_VIEW.customerView,
  ].includes(page);

  const loadDropdownOptions = async (searchQuery = '') => {
    if (searchQuery && searchQuery.length <= 2) {
      return { options: [], hasMore: false };
    }
    try {
      const ecosystems = getEcosystem();
      const dataOffset =
        firstLoading.current || !shouldLoadMore.current ? 0 : offset;

      const flag = getFlag(advanceSearchSelectedData.customerType);
      const state = advanceSearchSelectedData.state?.map((i) => i.label);
      const zip = advanceSearchSelectedData.zip?.map((i) => i.label);
      const city = advanceSearchSelectedData.city?.map((i) => i.label);
      const isPriority = firstLoading.current
        ? focusChecked
        : focusAccountChecked;

      const response = await getCustomerData(
        isPriority,
        searchQuery,
        dataOffset,
        roleId,
        unixId,
        isExactSearch,
        ecosystems,
        flag,
        state,
        zip,
        city,
        userEcosystem
      );
      const { hasMoreElement, data, currentListSize } = response.result;

      // Handle priority customers and priority check
      if (isPriority && data?.length > 0) {
        dispatch(setPriorityCustomers(data));
      } else if (
        page !== CONFIGURE_VIEW.teamSetup &&
        page !== CONFIGURE_VIEW.configurePriority
      ) {
        if (priorityCustomers?.length === 0) {
          dispatch(setFocusAccountChecked(false));
        }
        dispatch(setFocusAccountDisabled(priorityCustomers?.length === 0));
      }

      if (!searchQuery) {
        dispatch(setAllCustomers(data));
      }

      if (hasMoreElement) {
        setOffset((prevValue) => dataOffset + currentListSize);
      }

      return {
        options: data,
        hasMore: hasMoreElement,
      };
    } catch (error) {
      console.error('Error loading dropdown options:', error);
    }
  };

  const accountFocusAction = (value) => {
    dispatch(setFocusAccountChecked(value?.target?.checked));
    dispatch(setPrevFocusAccountChecked(value?.target?.checked));
  };

  useEffect(() => {
    dispatch(setAdvanceSearchSelectedData({}));
    dispatch(setFocusAccountChecked(focusChecked));
    dispatch(setFocusAccountDisabled(focusDisabled));
    !isDisabled &&
      page !== CONFIGURE_VIEW.teamSetup &&
      page !== CONFIGURE_VIEW.trends &&
      loadDropdownOptions();

    return () => {
      dispatch(setAdvanceSearchClicked({ id: page, value: false }));
      dispatch(setPrevFocusAccountChecked(true));
    };
    // eslint-disable-next-line
  }, [page]);

  const handleMenuOpen = () => {
    dispatch(setAdvanceSearchClicked({ id: page, value: false }));
  };

  return (
    <div>
      <Row>
        <Col
          md={7}
          className={classes['customer-column']}>
          {page === CONFIGURE_VIEW.trends && (
            <span
              className={`text text__bold badge-circle ${
                isDisabled && 'badge__disabled'
              } mb-1`}>
              3
            </span>
          )}
          <span
            className={
              page === CONFIGURE_VIEW.trends
                ? `${classes['customer-text-trends']} ${
                    isDisabled && 'disabled'
                  }`
                : classes['customer-text-cv']
            }>
            Customer
            <OverlayTrigger
              placement='right'
              overlay={
                !isDisabled ? (
                  <Tooltip bsclass='gne-tooltip tooltip--left'>
                    <p>
                      {CUSTOMER_TOOLTIP_TEXT}
                      <br />
                      {CUSTOMER_TOOLTIP_TEXT_PART2}{' '}
                    </p>
                  </Tooltip>
                ) : (
                  <></>
                )
              }
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 5],
                    },
                  },
                ],
              }}>
              <i className='icon icon__info__gray'></i>
            </OverlayTrigger>
          </span>
        </Col>
        <Col
          md={5}
          className={classes['col-group']}>
          <Col
            md={4}
            className={classes['column']}>
            <Button
              disabled={isDisabled}
              variant={advanceSearchClicked[page] ? 'primary' : ''}
              onClick={handleAdvanceSearch}
              size='sm'
              className={classes['advance-btn']}>
              <span
                className={
                  !advanceSearchClicked[page]
                    ? `${classes['advanced-title']} d-flex ${
                        isDisabled ? 'disabled' : ''
                      }`
                    : `${classes['advance-title-2']} d-flex`
                }>
                Advanced
                <i
                  className={
                    isDisabled
                      ? 'icon icon__search_gray ml-3 mt-1'
                      : !advanceSearchClicked[page]
                      ? 'icon icon__advance-search ml-3 mt-1'
                      : 'icon icon__advance-search-white ml-3 mt-1'
                  }></i>
              </span>
            </Button>
          </Col>
          <Col
            md={4}
            className={classes['column']}>
            <span>
              <span
                className={
                  isDisabled
                    ? `text ${classes['text-disable']}`
                    : `col-group span`
                }>
                Search Exact
              </span>
              <Checkbox
                value={isExactSearch}
                size='small'
                className='gne-checkbox no-padding'
                disabled={isDisabled}
                onChange={searchExacthandle}
              />
            </span>
          </Col>

          {page !== CONFIGURE_VIEW.configurePriority && (
            <Col
              md={4}
              className={classes['column']}>
              <span>
                <span
                  className={
                    focusAccountDisabled || isDisabled
                      ? `text ${classes['text-disable']}`
                      : `col-group span`
                  }>
                  Priority
                </span>
                <Checkbox
                  size='small'
                  className='gne-checkbox no-padding'
                  disabled={focusAccountDisabled || isDisabled}
                  onChange={accountFocusAction}
                  checked={focusAccountChecked}
                />
              </span>
            </Col>
          )}
        </Col>
      </Row>
      <Row className='m-0 g-0 p-0'>
        <Col md={12}>
          <GNEAsyncPagination
            value={selectedCustomer}
            isDisabled={isDisabled}
            loadOptions={loadDropdownOptions}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            formatOptionLabel={(data, { context }) =>
              formatCustomerFilterLabel(data, { context })
            }
            onChange={onChangeHandler}
            isMulti={isMulti}
            components={
              isMulti && {
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }
            }
            placeholder={CUSTOMER_HELP_TEXT}
            searchPlaceholder={CUSTOMER_HELP_TEXT}
            closeMenuOnSelect={!isMulti}
            isClearable={isClearable}
            cacheUniqs={[
              priorityCustomers,
              customerTypes,
              focusAccountChecked,
              isApply,
              advanceSearchSelectedData,
              selectedKPI,
              isExactSearch,
              clearDependencies,
            ]}
            shouldLoadMore={shouldLoadMoreOptions}
            onMenuOpen={handleMenuOpen}
            maxMenuHeight={
              page === CONFIGURE_VIEW.teamSetup
                ? 230
                : page === CONFIGURE_VIEW.configurePriority
                ? 180
                : 280
            }
          />

          {!advanceSearchClicked[page] &&
            advanceSearchSelectedData &&
            categoriesToDisplay.map((item, index) => {
              const items = advanceSearchSelectedData[item] || [];
              const category = item.charAt(0) + item.slice(1);
              return (
                <Dropdownpill
                  key={index}
                  category={category}
                  items={items}
                  page={page}
                />
              );
            })}
        </Col>
      </Row>
    </div>
  );
};
export default CustomerDropdown;
